/**
 * page-programmes.less
 */
.page-programmes,
.page-programmes-neufs{
    .page-header{
        margin-top: 100px;
    }
    .views-row{
        background-color: white;
        padding: 1.5em !important;
        box-shadow: 0px 3px 15px -3px rgba(0,0,0,0.5);
        margin: 20px 0;
        @media (min-width: @screen-sm){ // min 768
            margin: 15px;
        }
        @media (min-width: @screen-lg){ // min 1200
            margin: 20px;
        }
        a{
            text-decoration: none;
        }
    }
}