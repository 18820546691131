/**
 * c-list-programmes.less
 */

// Liste des programme sous forme de cards simple
.c-list-programmes{
    .tpz_block_programmes_similaires();
	margin-top: 20px;
	padding-top: 20px;
    padding-bottom: 20px;

    background-color: transparent;

	// Infinite scroll
    .pager{
    	display: none;
    }
}

// Liste des programmes Full Width
.c-list-programmes--fw{
	.views-row {
		clear: both;
		overflow: hidden;
		margin: 45px 0;
		display: flex;
		flex-direction: column;
    }

	.col.right{
		padding-right: @grid-gutter-width;
		padding-left: @grid-gutter-width;
		padding-bottom: @grid-gutter-width;
		padding-top: 2*@grid-gutter-width;
		position: relative;
		background: #fff;
	}

	.views-field-view-node,
	.views-field-view-node a {
		position: absolute;
		// background-color: red;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		font-size: 0;

		// margin-right: 25px;
		// float: right;
		// span {
		// 	.btn-default;
		// 	a {
		// 	  color: #fff;
		// 	}
		// }
    }

    .views-field-view-node-1{
		margin-top: 2*@grid-gutter-width;
    	text-align: right;
    }

    .node-programme_location .views-field-field-programme-dispositifs {
		display: none;
    }
    .views-field-field-img-header {
		img {
			width: 100%;
			height: auto;
		}
    }
    .views-field-field-programme-cat-principale {
		position: absolute;
		top: 30px;
		left: 0;
		z-index: 2;
		padding: 23px 25px;
		background: rgba(255, 255, 255, 0.7);
		color: @grisbrun;
		font-weight: bold;
		text-transform: uppercase;
		a {
			color: @grisbrun;
		}
    }
    .views-field-php, .views-field-ops {
		.tpz_flag_bookmark;
		float: right;
		margin-top: 40px;
		.flag-message {
			top: 2em;
			left: -170px;
			background: #fff;
			padding: 3px 6px;
			-webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
			box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
		}
    }
    .views-field-title,
    .views-field-field-nom-programme {
		// margin-top: 40px;
		font-size: @font-size-h2;
		line-height: 110%;
		font-weight: 300;
		color: #8d8986;
		text-transform: uppercase;
		position: relative;
		
		h2{
			margin-bottom: 0;
			margin-top: 0;
		}

		a {
			color: #8d8986;
			display: block;
		}
    }
    .views-field-field-programme-ville {
		font-size: @font-size-h2;
		line-height: 110%;
		font-weight: bold;
		color: #54514f;
		text-transform: uppercase;
		position: relative;
		
		a {
			color: #54514f;
			display: block;
		}
    }
    .views-field-field-code-postal {
		font-size: @font-size-h4;
		margin-bottom: 1em;
		font-weight: 400;
		color: #8d8986;
		text-transform: uppercase;
		position: relative;
		a {
			color: #8d8986;
			display: block;
		}
    }
    .views-field-field-accroche,
    .views-field-field-description,
    .views-field-field-desc-view {
		// margin: 1em 0;
		margin-top: 0;
		margin-bottom: 1em;
		font-size: @font-size-h4;
		font-weight: 700;
		color: @gray;
		position: relative;
    }
    .views-field-field-programme-dispositifs {
		margin-bottom: 10px;
		.field-content {
			ul {
				display: inline;
				padding: 0;
				li {
					display: inline;
					padding: 2px 5px;
					background: #b9b6b4;
					color: #fff;
					font-weight: 700;
				}
			}
		}
    }
    .views-field-view {
		div {
			display: inline;
		}
		.view-id-logements_types_lot .views-row {
			margin: 0; background: #b9b6b4; margin: 0 3px;
		}
		.view-id-logements_types_lot_location {
			background: pink;
		}
      	.field-content { padding: 2px 5px; color: #fff; font-weight: 700; }
    }
    .views-field-field-prix-min {
		margin-top: 32px;
		color: @brand-primary;
		font-size: 1.71em;
		margin-bottom: 5px;
		position: relative;
		// font-weight: 600;
		// float: left;

		.field-content{
			font-size: @font-size-h2;
			font-weight: bold;
			white-space: nowrap;
		}
    }

    .views-field-field-brochure{
    	position: relative;
    	display: inline-block;
    }
    

    @media @tablet{
    	.views-row{
			flex-direction: row;
    	}
    	.col.right{
    		padding-left: 2*@grid-gutter-width;
    	}

    	.views-field-field-programme-cat-principale{
			font-size: @font-size-h3;
    	}
    }
}