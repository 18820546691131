﻿// .page-trouver-votre-bien, 
// .page-investir-avec-topaze, 
// .page-nos-realisations, 
// .page-mes-programmes-favoris {

  /* Filtres */
  // #mini-panel-logement_filtres_habitation,
  // #mini-panel-logement_filtres_investir {
  //   .column {
  //     padding-right: 10px;
  //     padding-left: 10px;
  //   }
  //   .tpz_search_filters;
  // }


/* Résultats */
.view-id-flag_bookmarks {
	&:extend(.c-list-programmes--fw);
}
.view-id-logements_types_lot_location, 
.view-id-logements_types_lot_location .view-content {
    display: inline;
}
.view-id-logements_types_lot_location .views-row {
    display: inline;
    margin: 0; background: #b9b6b4; margin: 0 3px;
	color: #fff; font-weight: 700; padding: 2px 5px;
}

// }
