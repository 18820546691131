/**
 * alerts.less
 */

.alert{
	ul li{
		display: list-item;
	}
}

// Treat all links inside alert as .alert-link
.alert a {
	font-weight: @alert-link-font-weight;
}
.alert-success a {
	color: darken(@alert-success-text, 10%);
}
.alert-info a {
	color: darken(@alert-info-text, 10%);
}
.alert-warning a {
	color: darken(@alert-warning-text, 10%);
}
.alert-danger a {
	color: darken(@alert-danger-text, 10%);
}