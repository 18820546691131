/**
 * icon-svg.less
 * @desc : feuille de style pour le wrapper d'un svg
 */

.icon-svg{
	height: 20px;
	width: 20px;
	display: inline-block;
	vertical-align: middle;

	path,
	polygon{
		fill : currentColor;
	}
}

.icon-svg--s-medium{
	height: 30px;
	width: 30px;
}

.icon-svg--block{
	display: block;
}

