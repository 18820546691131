/**
 * accordion.less
 */

.accordion-toggle{
	// .row();
	background-color: @gray-light;
	cursor: pointer;
	padding-top: 0.75*@grid-gutter-width;
	padding-right: 	1.5*@grid-gutter-width;
	padding-bottom: 0.75*@grid-gutter-width;
	padding-left: 0.5*@grid-gutter-width;
	position: relative;
	margin-right: 0 - 0.75*@grid-gutter-width;
	margin-left: 0 - 0.75*@grid-gutter-width;

	& + .accordion-content{
		background-color: @gray-light;
		margin-right: 0 - 0.75*@grid-gutter-width;
		margin-left: 0 - 0.75*@grid-gutter-width;
		padding-right: 	1.5*@grid-gutter-width;
		padding-left: 0.5*@grid-gutter-width;
	}

	&.accordion-toggle{
		margin-bottom: 0;
	}
	&:not(:first-of-type){
		margin-top: 0;
	}

	&:after{
		content: "";
		display: block;
		width: 	1.5*@grid-gutter-width;
		height: 1.5*@grid-gutter-width;
		position: absolute;
		right: 0;
		top: 50%;
		margin-top: 0 - 0.75*@grid-gutter-width;
		background-image: url(../img/svg/arrow-right.svg);
		background-position: center center;
		background-size: 20px 20px;
		background-repeat: no-repeat;
		transition: transform 300ms ease-in-out;
	}

	&.is-expanded:after{
		transform: rotateZ(90deg);
	}

	&:nth-of-type(2n){
		background-color: @gray-lighter;

		& + .accordion-content{
			background-color: @gray-lighter;
		}
	}
}

.accordion-content{
	// height: 0;
	// transform: scale(0);
	max-height: 0;
	overflow: hidden;
	transition: all 500ms ease-in-out;


	&.is-revealed{
		max-height: 2000px;
		padding-top: @grid-gutter-width;
		padding-bottom: @grid-gutter-width;
		// display: block;
		// transform: scale(1);
	} 
}