/**
 * page-node-done.less
 */

.page-node-done{

	#page_section_image_header{
		height: 225px;
		background-color: @gray;
		background-image: url(../img/bg_node_done.jpg);
		background-position: right center;
		background-size: cover;
		
	}

	.page-header{
		color: @brand-primary;
	}

	#region_node_page_contenu{
		.tpz_main_container();

		.pane-contenu-page-normale {
			.tpz_main_container_inside();
			background-color: white;
		}
	}

	.webform-confirmation{
  		color: inherit;
  		background-color: transparent;
  		border: 0;
  	}
}