/**
 * img-card.less
 */

.img-card-list{
	.view-content{
		font-size: 0;
	}
}

.img-card{
	@tmp_width_desktop: ~"calc(50% - @{grid-gutter-width})";
	@tmp_width_mobile: 	~"calc(100% - @{grid-gutter-width})";

	display: inline-block;
	vertical-align: top;
	margin-right: 0.5*@grid-gutter-width;
	margin-left: 0.5*@grid-gutter-width;
	width: @tmp_width_mobile;
	font-size: 1rem;
	// background-color: red;
	position: relative;
	margin-bottom: @grid-gutter-width;
	min-height: 150px;
	background-color: @gray-light;
	// height: 0;
	// padding-bottom: ~"calc( (313 / 530) * ( @{tmp_width_desktop - @{grid-gutter-width} ) )";

	.views-field-nothing-2{
		// position: absolute;
		bottom: 0;
		right: 0*0.5*@grid-gutter-width;
		left: 0*0.5*@grid-gutter-width;
	}

	&_absolute{
	}

	&__txtwrapper{
		padding: 12px 15px;
		background-color: fadeout(white, 13%);
	}

	&__highlight{
		font-weight: bold;
	}

	&__small{
		margin-left: 20px;
		line-height: 1.5em;
	}

	&__price{
		color: @brand-primary;
		font-weight: bold;
		font-size: 1.5em;
	}

	.btn{
		text-transform: uppercase;
		width: 100%;
		font-size: 1.125em;
		font-weight: bold;
	}

	img{
		width: 100%;
		height: auto;
	}

	@media (min-width: @screen-xs-min){
		.views-field-nothing-2{
			position: absolute;
		}
	}

	@media @tablet{
		width: @tmp_width_desktop;
		
		.views-field-nothing-2{
			width: 80%;
			left: auto;
			padding-bottom: 0.5*@grid-gutter-width;
			padding-right: @grid-gutter-width;
		}

		&__txtwrapper{
			margin-right: @grid-gutter-width;
			display: inline-block;
			margin-bottom: 0.5*@grid-gutter-width;
		}
	}

	@media (min-width: @screen-md){
		.views-field-nothing-2{
			width: 60%;
		}

	}
}