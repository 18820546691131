.node-type-page-simple{
    .main-container{
        padding-top: @height-menu-mobile;
    }
    h1{
        padding-top: 0;
        // font-size: 0;
    }
    .block-webform{
        padding: @grid-gutter-width;
    }
    @media (min-width:@screen-xs) {
        .main-container{
            padding-top: @height-menu-desktop;
        }
        h1{
            padding-top: 84px;
        }
    }

}
.node-page-simple{
    padding-bottom: @grid-gutter-width;
}