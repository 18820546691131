/**
 * modal.less
 */


.modal{
	.webform-submit{
		.btn-secondary();
		margin-right: auto;
		margin-left: auto;
		margin-bottom: @grid-gutter-width;
	}
	.webform-component--mentions-legales{
		color: inherit;
	}
}

.modal-content{
	box-shadow: none;
	border: 0;
}

.modal-header{
	color: white;
	text-align: right;
	padding-right: 0;
	padding-left: 0;
	border-bottom: none;
		

	.close{
		opacity: 1;
		color: inherit;
		font-weight: normal;
		text-transform: uppercase;
		float: none;
		font-size: 18px;

		span{
			display: inline-block;
			vertical-align: middle;
		}
	}

	.icon-svg{
		width: 25px;
		height: 25px;
		margin-left: 0.5*@grid-gutter-width;
	}
}

.modal-title{
	text-transform: uppercase;
	font-size: 	2.57em;
	margin-bottom: 1.5*@grid-gutter-width;
}


.modal-body{
	.bg--topaze();
	background-color: @brand-primary;
	color: white;
}