/**
 * section-image-header.less
 */

.section-image-header{
    .tpz_header_img;

    .onglets {
      .tpz_search_tabs;
    }
}

.section-image-header--programme{
	// #programme_section_image_header
}

#programme_section_image_header {
	// .tpz_header_img;
	overflow: visible;
	img{
		max-width: 100%;
		height: auto;
		width: auto;
		min-height: auto;
		@media (min-width:@screen-sm-min) {
			min-height: 630px;
		}
	}
	@media @tablet{
		img{
			max-width: none;
		}
	}
	// 1920 is the size of the header image
	@media (min-width: 1920px ){
		img{
			min-width: 100%;
			width: auto;
		}
	}

	// .pane-logement-evenement-programme {
	//   	@media screen {
	//     	@media (min-width:@screen-sm-min) {
	//       		position: absolute;
	//       		left: 30%;
	//       		top: 20px;
	//       		left: 42%;
	//     	}
	//     	@media (min-width:@screen-md-min) {
	//       		top: 30px;
	//     	}
	//     	@media (min-width:@screen-lg-min) {
	//       		top: 40px;
	//       		left: 51%;
	//     	}
	//     	@media (min-width:1600px) {
	//       		top: 50px;
	//    		}
	//   	}
	//   	.column-inside-last {
	//     	width: 100%;
	//     	position: relative;
	//     	display: inline-block;
	//     	background: @brand-primary;
	//     	padding: 15px 35px 25px 30px;
	//     	text-align: right;
	//     	color: #fff;
	//     	font-size: @font-size-h4;
			
	// 		@media screen {
	// 			@media (min-width:@screen-md-min) {
	// 				font-size: @font-size-h2;
	// 				padding: 30px 70px 50px 60px;
	// 			}
	// 		}
	// 	    .pane-node-field-programme-evt-offre {
	// 	    	text-transform: uppercase;
	// 	    	font-size: @font-size-h3;
	// 	    }
	// 	    .pane-node-field-programme-evt-offre-date{
	// 	    	text-transform: uppercase;
	// 	    	font-size: @font-size-h3;
	// 	    }
	// 	    a {
	// 	    	position: absolute;
	// 	    	background: #fff;
	// 	    	color: @text-color;
	// 	    	text-transform: uppercase;
	// 	    	font-size: @font-size-h4;
		      
	// 			@media screen {
	// 				display: none;
	// 				bottom: -30px;
	// 				left: -40px;
	// 				padding: 7px 17px;
	// 				@media (min-width:@screen-sm-min) {
	// 					display: block;
	// 				}
	// 				@media (min-width:@screen-md-min) {
	// 					bottom: -20px;
	// 					left: -20px;
	// 					padding: 15px 35px;
	// 					font-size: @font-size-h3;
	// 				}
	// 			}
 //      		}
 //    	}
 //  	}
}

.section-image-header--home{
    max-width: 100%;
    overflow: hidden;
    font-size: 0;
    
    .photo_famille {
        display: none;
        
        @media (min-width:@screen-md-min) {
            display: inline-block;
            vertical-align: top;
            width: 26.95%;
            img {
                display: none;
            }
        }
    }
    .panel-separator {display: none;}
    .image_programme {
        position: relative;
        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 26.95vw;
            margin-left: -26.95vw;
            background-image: url(../img/home_header_family.jpg);
            background-size: contain;
            background-position: right top;
            background-repeat: no-repeat;   
        }
        @media screen {
            @media (min-width:@screen-md-min) {
                display: inline-block;
                width: 100% - 26.95%;
            }
        }
    }
}