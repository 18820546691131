/**
 * slick.less
 */

.slick--programme{

	@media @tablet{
		.slick-dots{
			top: 0;
			right: 0;
			bottom: 0;
			left: auto;
			width: @grid-gutter-width + 17px;
			display: flex !important;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			
			li{
				display: block;
			}
		}
	}
}

.slick-nav--programme{
	.slick-slide{
		position: relative;
		cursor: pointer;
		
		&:before{
			content: "";
			position: absolute;
			z-index: 1;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: fadeout(black, 70%);
		}
	}
	.slick-slide__title{
		position: absolute;
		z-index: 1;
		top: 50%;
		left: 0;
		right: 0;
		text-align: center;
		transform: translateY(-50%);
		font-style: italic;
		color: white;
		font-size: 1.3em;
		line-height: 1.3em;
		padding-right: @grid-gutter-width;
		padding-left: @grid-gutter-width;

		@media (min-width: @screen-md){
			font-size: 1.71em;
		}
	}
	.slick-current{
		.slick-slide__title{
			font-style: initial;
			font-weight: bold;
			color: @brand-primary;
		}
		&:before{
			// display: block;
			background-color: fadeout(white, 20%);

		}
	}

	.slick-dots{

	}
}

.slick-vertical{
	.slick-slide{
		border: 0;
	}


}

.slick-dots{
	bottom: 0;
	padding-bottom: 0.5*@grid-gutter-width;
	justify-content: center;

	li{
		background-color: white;
		border-radius: 50%;
		width: 17px;
		height: 17px;
		margin: 5px;

		button{
			width: 17px;
			height: 17px;
			background-color: white;
			border-radius: 50%;
			border: solid 3px white;
		}
		button:before{
			display: none;
		}
	}
	.slick-active{
		button{
			background-color: @brand-primary;
		}
	}
}