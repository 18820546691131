/**
 * carousel.less
 */

.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
            transition-duration: 0.75s;
         }
    
        .item,
        .active.left,
        .active.right { opacity: 0; }
    
        .active,
        .next.left,
        .prev.right { opacity: 1; }
    
        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }
    .carousel-control { z-index: 2; }
}


.carousel--home{
	font-size: @font-size-base;
	img {
	    width: 100%;
	    height: auto;
	}

    .carousel-inner { overflow: visible; }
	

	.carousel, 
	.carousel-inner, 
	.carousel-inner .item {
		height: 100%;
	}
    .views-field-field-lien { display: none; }

    .views-field-title, 
    .views-field-field-imghome-surtitre, 
    .views-field-field-imghome-sous-titre {
        position: absolute;
        left: 150px;
        top: 50px;
        line-height: 1.5em;
        font-weight: 900;
        
        a {
            color: #fff;
            text-shadow: 0px 0px 6px #000;
            &:hover,
            &:active,
            &:focus {
                text-decoration: none;
            }
        }
    }
    .views-field-title {
        top: 10%;
        right: @grid-gutter-width;
        font-size: 8vw; 
        line-height: 1em;
    }
    .views-field-field-imghome-surtitre {
        top: 40%;
        font-size: 4vw;
    }
    .views-field-field-imghome-sous-titre {
        top: 86%;
        display: none;
    }


	.carousel-indicators {
	    margin-bottom: 0;
	    bottom: 5px;
	    right: 0;
	    left: 0;
	    width: auto;
	    margin-left: 0;
	    
	    li {
	        width: 15px;
	        height: 15px;
	        margin-right: 10px;
	        margin-left: 10px;
	    }
	}


	@media (min-width: @screen-sm-min){
		.views-field-title,
		.views-field-field-imghome-surtitre,
		.views-field-field-imghome-sous-titre{
			left: 30%;
			right: auto;
            font-size: @font-size-large;
		}
		.views-field-title{
			top: 30%;
            font-size: 5em;
		}
		.views-field-field-imghome-surtitre,{
			top: 55%;
		}
	}

	@media (min-width:@screen-md-min) {
		.views-field-title{
			top: 20%;
		}
		.views-field-field-imghome-surtitre{
			padding-left: 5px;
            top: ~"calc(20% + 1.5*5*@{font-size-base})";
		}
		.views-field-field-imghome-sous-titre{
			display: block;
            padding-left: 5px;
            top: ~"calc(20% + 1.5*5*@{font-size-base} + 1.5*@{font-size-large})";
		}

		.carousel-indicators{
	        bottom: 10%;
	        li{
	            width: 12px;
	            height: 12px;
	            margin-right: 2px;
	            margin-left: 2px;
	        }
		}
	}
}