.page-nos-realisations {
  .container {
    margin: 0 auto;
  }

  #page_section_contenu {
    #region_node_page_contenu {
      .tpz_main_container;
      h1 {
        text-transform: uppercase;
        font-weight: 300;
        color: @brand-primary;
        strong {
          font-weight: 600;
          color: @gray-dark;
        }
      }
    }
  }
}
