/**
 * panel.less
 */

.panels-bootstrap-column{
	min-height: 0;
}


.pane-views-aide-construction-projet-block{
	.tpz_block_programmes_similaires();

	h2{
		margin-top: 5*0.5*@grid-gutter-width;
		margin-bottom: 2*@grid-gutter-width;
	}


	.view-field-title{
		// font-size: 
	}
	.view-content{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
	// .views-row{
	// 	width: ~"calc(100% - 2*1em - 2*3px)";
	// 	display: flex;
	// 	flex-direction: column;

	// 	@media @tablet{
	// 		// 3px is because the display is inline-block
	// 		width: ~"calc( 33.3333333% - 2*1em - 2*3px )";
	// 	}
	// }
	.views-row .views-field-title{
		font-size: 1.14em;
	}
	.views-row img{
		width: 100%;
		height: auto;
	}
	.views-field-view-node{
		flex: 1 1 0%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
	.views-row .views-field-view-node{
		margin-bottom: 0;
		a{
			display: inline-block;
			width: auto;
			padding-top: 0.5*@grid-gutter-width;
			padding-bottom: 0.5*@grid-gutter-width;
			padding-right: 1.5*@grid-gutter-width;
			padding-left: 1.5*@grid-gutter-width;
		}
	}
}


// Panel dans la banner d'une page programme
.pane-logement-evenement-programme{

	top: 0;
	bottom: 0;
	z-index: 1;
	color: @gray-dark;

	& > .pane-content > .panel-display{
		background-color: fadeout(white, 10%);
		padding-right: 0.5*@grid-gutter-width;
		padding-left: 0.5*@grid-gutter-width;
	}

	.pane-node-field-etat-programme{
		.row();
		padding: @grid-gutter-width;
		background-color: @brand-primary;
		text-align: center;
		color: white;
		text-transform: uppercase;
		font-size: 1.28em;
		position: relative;
		&:before{
			content: "";
			display: none;
			position: absolute;
			width: @grid-gutter-width;
			height:0.5*@grid-gutter-width;
			position: absolute;
			left: 0;
			bottom: 0;
			transform: translateY(100%);
			border-left: solid @grid-gutter-width transparent;
			border-top: solid 0.7*@grid-gutter-width darken(@brand-primary, 20%);
		}
	}

	.pane-node-title h2,
	.field-name-field-nom-programme {
		font-size: 2.57em;
		color: inherit;
		text-align: left;
		font-weight: normal;
		text-transform: uppercase;
		line-height: 1em;
		margin-top: 20px;
		margin-bottom: 10px;
	}

	.field-name-field-location,
	.field-name-field-programme-evt-offre-date{
		font-size: 1.71em;
		padding-left: 34px + 11px;
		position: relative;
		// font-weight: bold;

		&:before{
			content: '';
			position: absolute;
			left: 0;
			width: 34px;
			height: 34px;
			display: block;
			background-repeat: no-repeat;
			background-position: left center;
			background-size: 23px 28px;
		}
	}
	.field-name-field-location:before{
		background-image: url(../img/svg/placeholder--gray-dark.svg);
	}
	.field-name-field-programme-evt-offre-date:before{
		background-image: url(../img/svg/calendar--gray-dark.svg);
	}

	.field-name-field-description{
		font-size: 1.28em;
		margin-top: @grid-gutter-width;
	}

	.field-name-field-prix-min{
		font-size: 1.71em;
		color: @brand-primary;
		font-weight: bold;
		margin-top: 0.5*@grid-gutter-width;

		@media @tablet{
			font-size: 2.14em;
		}
	}

	.pane-mini-panel-programme-banner-foot{
		.row();
		margin-top: @grid-gutter-width;
	}




	@media @tablet{
		.container();
		// background-color: red;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);

		& > .pane-content > .panel-display{
			width: 70%;
			position: absolute;	
			top: 50%;
			transform: translateY(-50%);
			right: 0;
			margin-top: @grid-gutter-width;
			padding-right: 1.5*@grid-gutter-width;
			padding-left: 1.5*@grid-gutter-width;
		}
		
		.pane-node-field-etat-programme{
			display: inline-block;
			margin-right: 0;
			margin-left: 0 - 2.5*@grid-gutter-width;
			margin-top: 0 - 3*@grid-gutter-width;
			&:before{
				display: block;
			}
		}

		.pane-mini-panel-programme-banner-foot{
			margin-right: 0 - 1.5*@grid-gutter-width;
			margin-left: 0 - 1.5*@grid-gutter-width;
		}
	}

	@media (min-width: @screen-lg){
		
		& > .pane-content > .panel-display{
			width: 50%;
		}
	}
}

.pane-logement-descriptif-programme{
	.panels-bootstrap-row:last-of-type{
		.panels-bootstrap-column:last-of-type{
			margin-top: @grid-gutter-width;
		}

		@media (min-width: @screen-md){

			.panels-bootstrap-column:first-of-type{
				padding-right: 1.5*@grid-gutter-width;
			}
			.panels-bootstrap-column:last-of-type{
				margin-top: 0;
				padding-left: 1.5*@grid-gutter-width;
			}
		}
	}

	.row-inside-last.row-inside-last{
		display: block;
		
		@media @tablet{
			flex-wrap: nowrap;
		}
	}
}

// MINI PANELS
// -------------------------------------------------------
	.pane-mini-panel-header-programme{
		.panels-bootstrap-row{
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
		}

		.panels-bootstrap-column{
			display: flex;
		}

		.panels-bootstrap-region{
			display: flex;
			flex-direction: column;
			width: 100%;
		}

		.pane-logement-descriptif-programme{
			display: flex;
			flex: 1;

			& > .pane-content {
				width: 100%;
			}
		}

		#panel-bootstrap-region-right{
			width: 100%;
		}
		.pane-webform--contact-programme{
			// flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;

			form{
				margin-right: auto;
				margin-left: auto;
			}
			
			@media @tablet{
				form{
					max-width: 460px;
				}
			}

		}
		.pane-views-getlocations-map-nearby-block-5{
			flex: 1 1 0%;
			display: flex;
			flex-direction: column;
			
			.pane-content,
			.view,
			.view-content,
			.getlocations_map_wrapper,
			.getlocations_map_wrapper_inner,
			.getlocations_map_wrapper_inner > div{
				display: flex;
				flex: 1 1 0%;
				flex-direction: column;
			}
		}
		.getlocations_map_wrapper_inner > div{
			min-height: 336px;
			height: auto !important;
		}

		@media @tablet{
			.panels-bootstrap-row{
				flex-direction: row;
			}			
		}
		@media (min-width: @screen-lg){
			.getlocations_map_wrapper_inner > div{
				// min-height: 0;
			}
		}
	}

	.pane-mini-panel-programme-residence{
		overflow: hidden; 
		.panels-bootstrap-row{
			display: flex;
			flex-direction: column-reverse;
			flex-wrap: wrap;
		}

		.panels-bootstrap-column:first-child{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
		}

		.field-type-image{
			img{
				.img-responsive();
			}
		}

		@media @tablet{
			.panels-bootstrap-row{
				flex-direction: row;
				flex-wrap: nowrap;
			}
			// .panels-bootstrap-column:first-child,
			.panels-bootstrap-column.column{
				width: 50%;
				overflow: hidden;
			}

			.field-type-image{
				img{
					max-width: none;
				}
			}


		}
	}

	// Meni panel avec le tel et la documentation
	.pane-mini-panel-programme-banner-foot{
		font-size: 1.14em;

		@media @tablet{
			font-size: 1.28em;
		}

		.panel-col{
			& > div{
				display: flex;
				flex-wrap: wrap;
			}
		}

		section{
			display: flex;
			position: relative;
			width: 50%;
			justify-content: center;

			&:first-child{
				flex: 1 1 0%;
			}
			&:last-child{
				&:before{
					content: "";
					display: block;
					position: absolute;
					top: 0.5*@grid-gutter-width;
					left: 0;
					bottom: 0.5*@grid-gutter-width;
					right: auto;
					width: 1px;
					height: auto;
					background-color: white;
				}
			}
		}

		button,
		.btn{
			border: none;
			text-transform: uppercase;
			font-size: 1em;
			width: 100%;
			color: white;
			padding: 16px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			font-weight: bold;
			white-space: normal;
			justify-content: center;

			span + span{
				flex: 1 1 0%;
			}
		}
		.btn-phone span{
			@media @tablet{
				text-align: left;
			}	
		}
		.icon-svg{
			width: 100%;
			height: 22px;
			display: flex;
			flex-direction: column;
			margin-right: 0.5*@grid-gutter-width;
			margin-left: 0.5*@grid-gutter-width;
			margin-top: 0.5*0.5*@grid-gutter-width;
			margin-bottom: 0.5*0.5*@grid-gutter-width;

			@media @tablet{
				width: 43px;
				height: 46px;
				display: inline-block;				
			}
		}

		.btn-phone_main{
			text-align: center;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			width: 100%;
			justify-content: center;

			&:before{
				height: 50px;
				width: 50px;
				display: none;
			}
		}

		.btn-phone_toreveal{
			font-size: 0.75em;
		}

		.block-block--phone-programme{
			.btn-phone {
				color: @brand-primary;
				background-color: white;
			}
		}
	}



	.pane-mini-panel-programme-pre-footer,
	.pane-mini-panel-prog-residence-footer{
		clear: both;

		.panel-col > div{
			display: flex;
			flex-wrap: wrap;
		}

		.block-block{
			width: 100%;
			padding: 1.5*@grid-gutter-width @grid-gutter-width;
			color: white;
			text-align: center;
			display: flex;

			h3{
				font-size: 1.71em;
				margin-top: 0.5*0.5*@grid-gutter-width;
				margin-bottom: 0.5*0.5*@grid-gutter-width;
			}

			p{
				margin-bottom: 0.5*0.5*@grid-gutter-width;
				font-size: 1.71em;
			}

			.btn{
				font-weight: bold;
				text-transform: uppercase;
				font-size: 1.28em;
			}

			@media @tablet{
				padding-right: 1.5*@grid-gutter-width;
				padding-left: 1.5*@grid-gutter-width;
			}
		}
		.block-block > .row--flex{
			max-width: 775px;
			width: 100%;
			margin-right: auto;
			margin-left: auto;
		}
		.block-block:first-of-type{
			background-color: @brand-primary;
		}
		.block-block:nth-of-type(2){
			background-color: @taupe_dark;
		}


		@media @tablet{
			.block-block{
				width: 50%;
				text-align: initial;

				.col:last-of-type{
					text-align: center;
				}
			}
		}

	}

	.pane-mini-panel-programme-slider{
		clear: both;

		.panel-col{
			& > div{
				display: flex;
				// align-items: flex-start;
			}
		}

		.block-views:first-of-type{
			width: 100%;
		}
		.block-views:nth-of-type(2){
			width: 100%;
			display: none;
		}

		img{
			width: 100%;
			height: auto;
		}

		
		@media @tablet{
			height: 0;
			padding-bottom: 100% * (820 / 1920);
			overflow: hidden;
			
			.block-views:first-of-type{
				width: 	67%
			}
			.block-views:nth-of-type(2){
				width: 	33%;
				display: flex;
				flex-direction: column;
				
				.view{
					display: flex;
					flex: 1 0%;
				}
				.view-content{
					display: flex;
					width: 100%;
				}
				.slick-list{
					height: auto !important;
					display: flex;
					width: 100%;
				}
				.slick-track{
					height: auto !important;
					display: flex;
					flex-direction: column;
					width: 100%;
				}
				.views-row{
					flex: 1;
					display: flex;
					height: 0;
					padding-bottom: 44%;
					width: 100% !important;
					position: relative;
				}
				.views-field-field-images-slider{
					display: flex;
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
				}
				.field-content{
					width: 100%;
				}

				img{
					// position: absolute;
					height: 100%;
					width: auto;
				}
			}
		}
	}