/**
 * menu-transversal
 */

.menu-transversal{
    padding: 60px 0;
    background: #fff;
    text-transform: uppercase;
    font-weight: 600;
    
    @media screen {
        @media (min-width:@screen-lg-min) {
        }
    }

	.nav > li > a,
	.dropdown-menu a,
	.dropdown-menu > .active > a {
        color: @taupe;

        &:hover, 
        &:active,
        &:focus{
            background-color: darken(@taupe, 15%);
            color: #fff;
        }
		
	}


    .nav > li{
        display: inline-block;
        width: 98%;
        margin: 0 1% 20px;

        & > a {
            padding: 0 0 0 60px;
            height: 80px;
            color: white;
            line-height: 80px;
            background-position: 20px 50%;
        	background-color: @taupe;
        }
    	
    }
    .dropdown-menu {
        margin: 0;
        box-shadow: none;
        border: 1px solid @taupe;
        width: 100%;

        a {
            color: @taupe;
            white-space: normal;
            line-height: 1em;
        }

        & > .active > a{
	        background-color: #fff;
	        font-weight: bold;
        }
    }

    @media (min-width:@screen-sm-min) {
        .nav > li{
        	width: 47%;
        }
    }
    @media (min-width: @screen-lg-min) {
    	padding-bottom: 40px;

    	.nav > li{
    		width: 23%;
            &.first { margin-left: 0;}
            &.last { margin-right: 0;}
    	}
    }

}

#accueil_section_menu_transversal {
    #panel-bootstrap-region-menu_transversal {
        .nav > li {
            @media screen {
            }
        }
    }
}