/**
 * base.less
 */

html{
	font-size: @font-size-base;
}

img{
	&:extend(.img-responsive);
}

// Titres
.title--h2{
	color: @brand-primary;
	font-weight: bold;
	text-transform: uppercase;
	margin-bottom: @grid-gutter-width;
	font-size: 1.71em;

	.icon-svg{
		margin-bottom: @grid-gutter-width;
	}
}




// VISIBILITY
// ------------------------------------------------
	.visuallyhidden{
		font-size: 0;
		width: 1px; 
		height: 1px;
		display: inline-block;
		overflow: hidden;
		position: absolute !important;
		border: 0 !important;
		padding: 0 !important;
		margin: 0 !important;
		clip: rect(0px, 0px, 0px, 0px);
	}

// TITRES
	.title-rubrique{
		h1{
            color: @brand-primary;
            margin-top: 0;
		}
		h1 + p{
			.tpz_titre_rubrique;
		}
	}

// BACKGROUND
	.bg--topaze{
		background-image: url(../img/svg/logo-topaze.svg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
	}