// .page-node-3582,
// .page-node-3594,
// .page-node-223,
// .page-telecharger-brochure,
// .page-telecharger-guide
// .node-type-webform,
// .page-node-done
// {
	
//   background-color: @brand-primary;
  
//   .page-header {
//     font-size: 2.57em;
//   //   font-weight: 700;
//   //   color: @brand-primary;
//   //   padding-bottom: 0;
//   //   text-transform: uppercase;
//     border-bottom: 0;
//   }
//   #navbar,
//   .tabs--primary,
//   .footer_top,
//   #footer_wrapper,
//   #toolbar_region {
//     display: none;
//   }

// 	form {
// 		.tpz_contact_form;
// 		// .webform-component--champs {
// 		// 	padding: 2em 10%;
// 		// }
//     .webform-component--champs--votre-projet-concerne {
//       margin-bottom: 10px;
//     }
// 		// .webform-component--champs--nom, 
//   //   .webform-component--champs--prenom {
// 		// 	width: 49.68%;
// 		// }
// 		.webform-component--champs--code-postal,
//     .webform-component--champs--ville {
// 			display: inline-block;
// 		}
// 		// .webform-component--champs--code-postal {
// 		// 	width: 25%;
//   //     margin: 5px 0 0;
// 		// }
// 		// .webform-component--champs--ville {
// 		// 	width: 74.3%;
//   //     margin: 5px 0 0;
// 		// }
//     // .webform-component--champs--email {
//     //   width: 100%;
//     //   margin: 5px 0;
//     // }
// 		// .webform-component--champs--telephone {
// 		// 	width: 100%;
// 		// 	margin: 5px 0;
// 		// }
// 		// .webform-component--mentions-legales {
// 		// 	font-weight: 300;
// 		// 	font-style: italic;
// 		// 	color: @gray-dark;
// 		// }
// 	}
// }


.page-telecharger-guide,
.page-telecharger-brochure{
  .main-container{
    margin-top: 180px; 
  }
}


// .page-telecharger-brochure--confirmation {
//   .page-header {
//     font-size: @font-size-h2;
//     font-weight: 700;
//     color: @brand-primary;
//     padding-bottom: 0;
//     text-transform: uppercase;
//   }
//   #navbar,
//   .tabs--primary,
//   .footer_top,
//   #footer_wrapper,
//   #toolbar_region {
//     display: none;
//   }
// }