/**
 * c_section-teaser.less
 */

.section-teaser{
	background-color: @gray-light;
	background-image: url(../img/bg_brochure.jpg);
	background-repeat: no-repeat;
	background-position: bottom left;
	background-size: 100%;
	color: #fff;
	text-transform: uppercase;

	img {
		max-width: 100%;
		height: auto;
	}

	.inside{ font-size: 0;}

	.panel-pane{
		display: inline-block;
		float: none;
		vertical-align: middle;
		font-size: @font-size-h2;
	}

	.panel-separator{ display: none;}

	.panel-pane:last-child{
		text-align: center;
		padding-top: 10px;
		padding-right: 10px;
		padding-bottom: 10px;
		padding-left: 10px;
		font-size: 1.5*@font-size-base;


		a {
			padding: 10px 25px;
			font-size: @font-size-h4;
			font-weight: 700;
			background: #fff;
			color: @brand-primary;
			text-align: center;
			display: inline-block;
			
			&:hover,
			&:active,
			&:focus {
				background: @taupe;
				color: #fff;
				text-decoration: none;
			}
		}

		@media (min-width:@screen-md-min) {
			text-align: right;
			width: 335px;
			font-size: @font-size-h2;
		}
	}

}

.section-teaser--brochure{
	
	.pane-node-field-brochure { display: none;}
}


.section-teaser--programmes{

	.panel-pane:first-child{

	}

	.panel-pane:last-child{
		@media (min-width:@screen-md-min) {
			font-size: @font-size-h3;
		}
	}

}