/**
 * header.less
 */

.region-navigation {
    padding-left: 135px;
    // on se greffe sur le breakpoint de tb_megamenu
    // because he has breakpoint different than our theme
    @media (max-width: @screen-md + 13px){ // min 768
    }
}

.navbar {
    margin: 0;
    background: #fff;

    & > .container {
        padding-left: 0;
        padding-right: 0;
    }

    @media (max-width: @screen-md){
        & > .container{
            width: 100%;
        }
    }

    @media (max-width: @screen-xs){
        .logo img{
            height: 90px !important;
        }
    }
}

.show-menu{
    .navbar-fixed-top {
        height:100%;
        overflow-y:auto;
    }
    .navbar-header{
        display: none;
    }
    .region-navigation{
        padding-left: 0;
    }
}
.navbar-fixed-top {
    border: 0;
    @media (max-width: @screen-md) { //max 992px
        height:inherit;
        overflow-y:unset;
    }
}

.navbar-header {
    position: absolute;
    z-index: 10;

    .navbar-btn {
        margin: 0;
    }
    .navbar-brand {
        display: none;
    }
}

.tb-megamenu{

    .row-fluid [class*="span"]{
        margin-left: 0;
    }

    .row-fluid .span4{
        width: 100% / 3;  
    }

    .open > a{
        background-color: transparent;
    }
     .caret{
         display: none;
         z-index: 1;
     }

    .nav > .active > a, 
    .nav > .active > a:hover, 
    .nav > .active > a:focus{
        background-color: inherit;
        color: inherit;
    }
    
    .nav > .open > .dropdown-menu li > a{
        border-top: 0;
        border-bottom: 1px solid #eee;
    }

    .nav > .open > .dropdown-menu li > a:hover, 
    .nav > .open > .dropdown-menu li > a:focus{
        border-top: 0;
        border-bottom: 1px solid @brand-primary !important;
    }

    .nav-collapse .nav{
        top: 40px;
    }

    .mega-nav > li:first-child > a, 
    .dropdown-menu .mega-nav > li:first-child > a{
        padding-top: 10px;
    }

    .dropdown-menu .active > a, 
    .dropdown-menu .active > a:hover{
        background-image: none;
        background-color: #F6F6F6;
        border-bottom: 1px solid @brand-primary !important;
    }

    .nav li.dropdown.active > .dropdown-toggle{
        background-color: transparent;
    }
    .nav > .dropdown > .dropdown-toggle .caret{
        display: none;
    }
    .nav > li.dropdown.open.active > a:hover,
    .nav > li.dropdown.open.active > a:active,
    .nav > li.dropdown.open.active > a:focus,
    .nav li.dropdown.open.active > .dropdown-toggle{
        background-color: @brand-primary;
        color: white;
    }

    .nav-tabs .open .dropdown-toggle, 
    .nav-pills .open .dropdown-toggle,
    .nav > li.dropdown.open.active > a:focus{
        background-color: @brand-primary;
    }

    .dropdown-menu{
        font-size: 1em;
    }

    .dropdown-submenu{
        & > .dropdown-menu{
            margin-top: -10px; // same as padding of .mega-col-nav .mega-inner
            margin-left: 10px; // same as padding of .mega-col-nav .mega-inner
        }
    }

    @media (max-width: @screen-xs){
        .mega-nav > .level-2{
            margin-right: 48px;
        }
    }
    

    @media (max-width: @screen-md) { //max 992px
        .nav li.dropdown.open{
            & > .dropdown-toggle{
                background-color: transparent;
            }
        }
        .caret{
            border-top-width: 0.5*@grid-gutter-width;
            border-right-width: 0.25*@grid-gutter-width;
            border-left-width: 0.25*@grid-gutter-width;
            display: block;
        }
     
        .nav li.dropdown > .dropdown-toggle .caret,
        .nav li.dropdown > .dropdown-toggle:hover .caret{
            border-top-color: @brand-primary;
            border-bottom-color: @brand-primary; 
            margin-top: 6px;
            display: inline-block;
        }
        .nav li.dropdown.open > .dropdown-toggle .caret, 
        .nav li.dropdown.active > .dropdown-toggle .caret, 
        .nav li.dropdown.open.active > .dropdown-toggle .caret,
        .nav li.dropdown.open.active .caret, 
        .nav li.dropdown.open a:hover .caret, 
        .nav li.dropdown.open a:focus .caret{
            border-top-color: @brand-primary;
            border-bottom-color: @brand-primary; 
        }
        
        .nav-collapse {
            top: 0;
            background-color: red;
            margin-top: 0;
            font-size: (16px / @font-size-base)*1em;
            .open
            .nav{
                a {
                    margin-left: 0;
                    color: @text-color;
                    padding-right: 10px !important;
                }
            }
            .nav > li > a{
                font-size: 1em;
                padding-left: @grid-gutter-width;
                padding-right: @grid-gutter-width;
                border-bottom: none;
            }
            .nav > li > a:hover,
            .nav > li > a:focus,
            .nav > li.active > a:hover,
            .nav > li.active > a:focus{
                background-color: transparent;
                color: white;
            }
            .dropdown-menu a{
                border-top: none;
            }
        }
    }

    // tb_megamenu breakponit
    @media (max-width: (@screen-sm + 30px)){
        .nav-collapse .caret{
            display: inline-block !important;
            position: absolute;
            right: 0.5*(@height-menu-mobile - 0.5*@grid-gutter-width);
        }
        .nav-collapse .nav li a{
            border-top: none;
        }
    }
    
}

/* /Responsive */
.tb-megamenu-main-menu {

    &.tb-megamenu .nav{
        & > li.dropdown > .dropdown-toggle{
            color: inherit;
            font-weight: 300;
        }
    }

    .tb-megamenu-nav {
        background-color: white;
        display: flex;

        & > li > a:not(.btn) {
            background-color: #f6f6f6;
            margin-right: 5px;
            border: 0;
            color: @grisbrun;
            text-transform: uppercase;
            font-weight: 300;
            box-shadow: 5px -5px 5px -1px @gray-light;                               
            background-color: #fff;
            height: @height-menu-desktop;
            display: flex;
            flex-direction: column;
            justify-content: center;
            
            &:hover,
            &:active,
            &:focus {
                color: @grisbrun;
            }
        }

        & > li > .btn{
            .btn();
        }
        & > li > .btn-primary{
            .btn-primary();
        }
        
        & > li > .icon-svg{
            width: 67px;
            height: @height-menu-desktop;
            font-size: 0;
            border: 0;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            &:before{
                content: "";
                background-image: url(../img/svg/phone--white.svg);
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 30px 30px;
                display: inline-block;
                width: 30px;
                height: 30px;
            }
        }

        .active > a:not(.btn):hover,
        .active > a:not(.btn):focus {
            background-color: #F6F6F6;
        }

        & > li.dropdown {
            & > .dropdown-toggle {
                color: inherit;
            }

            &.active {
                & > .dropdown-toggle {
                    color: inherit;
                }
                & > a:hover {
                    background-color: @brand-primary;
                    color: #fff;
                }
            }
        }
        .dropdown-menu {
            border: 0;
            background-color: #f6f6f6;
            text-transform: uppercase;
            li {
                a {
                    padding: 10px;
                    font-weight: 600;
                    color: @text-color !important;
                }
            }
        }
    }

    // tab_megamenu breakpoint
    @media (max-width: (@screen-md - 13px)) { 
        .span12.mega-col-nav .mega-inner{
            padding-right: 0;
        }
        &.tb-megamenu .nav{
            
        }
        .tb-megamenu-nav{
            display: block;
            padding-top: @height-menu-mobile;
            & > li{
                background-color: #f6f6f6;
                margin-bottom: 14px;
                &:after{
                    content: "";
                    display: block;
                    position: absolute;
                    width: @height-menu-mobile;
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background-color: white;
                }
                & > .dropdown-toggle .caret{
                    position: absolute;
                    right:  0.5*@height-menu-mobile - 0.5*@grid-gutter-width;
                    top: @grid-gutter-width;
                }
            }
            & > li:first-of-type{
                margin-top: 14px;
            }
            & > li:last-of-type{
                padding-right: @height-menu-mobile;
                background-color: @brand-primary;
                position: absolute;
                margin-right: 0;
                top: 0;
                left: 0;
                right: 0;
            }
            & > li > a:not(.btn) {
                box-shadow: none;
                display: block;
                background-color: transparent;
                height: auto;
                padding-top: @grid-gutter-width;
                padding-bottom: @grid-gutter-width;

                & > strong{
                    color: @brand-primary;
                }
            }
            & > li > .icon-svg{
                top: 0;
                display: flex;
                justify-content: flex-start;
                padding-left: @grid-gutter-width;
                padding-right: @grid-gutter-width;
                width: auto;
                height: @height-menu-mobile;
                text-transform: uppercase;
                &:before{
                    margin-right: 0.5*@grid-gutter-width;
                }
            }
            .dropdown-menu{
                overflow: hidden;
            }
        } 
    }
}

// Hamburger
.tb-megamenu .btn-navbar {
    background-color: white;
    text-shadow: none;
    box-shadow: none;
    background-image: none;
    border: none;
    border-radius: 0;
    float: right;
    padding-right: 13px;
    padding-left: 13px;
    display: none !important;
    
    .icon-bar + .icon-bar{
        margin-top: 7px;
    }

    @media (max-width: (@screen-md - 13px)){
        margin-bottom: 0;
        display: block !important;
    }

    @media (max-width: @screen-sm){
        display: block !important;
    }
}

.navbar-toggler{
    .tpz-navbar-toggle();
    position: relative;
    // on mettra la bonne valeur de z-index plus tard
    z-index: 9999;
}


.header--moteur-recherche{
    // 70px is height of header, put this value in a variable
    min-height: ~"calc(100vh - 70px)";
    padding-top: @grid-gutter-width;
    padding-right: @grid-gutter-width;
    padding-bottom: @grid-gutter-width;
    padding-left: @grid-gutter-width;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-position: center center;
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;

    &:before{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background:linear-gradient(to right, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.2) 100%);
    }

    & > div{
        position: relative;
    }

    h1{
        text-transform: initial; 
        text-align:center;
        font-weight: bold;
        font-size: (@font-size-h2 / @font-size-base)*1em;
        color: white;
        margin-bottom: 2*@grid-gutter-width;
        margin-top: 0;

        span{
            display: block;
            font-style: italic;
            font-weight: normal;
            font-size: (@font-size-h3 / @font-size-h2)*1em;
            color:#fff;
        }
    }

    @media (min-width: @screen-sm){
        h1{
            font-size: (@font-size-h1 / @font-size-base)*1em;
        }
    }

    @media (min-width: @screen-lg){

    }
}

