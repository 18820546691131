#mini-panel-contenu_proposer_un_terrain {
	.tpz_main_container_inside;
	#panel-bootstrap-column-main-column {
		
		.pane-block {
			margin-top: 45px;
			img {
				max-width: 100%;
				height: auto !important;
			}
		}
	}
	#panel-bootstrap-column-1 {
		.field-name-body {
			margin-top: 30px;
		}
		.pane-webform {
			.pane-title {
				margin: 40px 0 30px;
				// font-size: @font-size-h1;
				font-weight: 300;
				color: @gray-dark;
				text-transform: uppercase;
				word-wrap: break-word;
				hyphens: auto;
			}
			// form {
			// 	.tpz_contact_form;
			// 	.webform-component--champs--nom,
			// 	.webform-component--champs--prenom {
			// 		width: 100%;
			// 	}
			// 	.webform-component-textfield {
			// 		margin: 3px 0;
			// 	}
			// 	.webform-component-checkboxes {
			// 		margin: 10px 0;
			// 		font-style: italic;
			// 		label {
			// 			padding-left: 30px;
			// 		}
			// 	}
			// }
		}
	}
}