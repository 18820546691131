/**
 * page-contact.less
 */
.page-contact {
    
    .pane-node-body {
        margin-bottom: 1.5*@grid-gutter-width;
        font-size: 16px;
        font-weight: 300;
    }

    #page_section_contenu {
        
        #region_node_page_contenu{
            // background-color: white;
            padding: 60px 30px;
            position: relative;
            @media (min-width: @screen-sm){ // min 768
                padding: 60px 90px;
            }
            &:before{
                content:"";
                display: block;
                position: absolute;
                top: 30px;
                right: 15px;
                left: 15px;
                bottom: 0;
                background-color: white;
                @media (min-width: @screen-sm){ // min 768
                    right: 45px;
                    left: 45px;
                }
            }

            .panel-pane{
                position: relative;
            }
        }
    }

    #mini-panel-formulaire_page_contact {
        .column-inside-first {
            @media screen {
                @media (min-width: @screen-md-min) {
                    padding-right: 70px;
                }
            }
        }
        .column-inside-last {
            .contactez-nous {
                .pane-title {
                    margin: 0 0 40px;
                    font-size: @font-size-h1;
                    font-weight: 300;
                    color: @gray-dark;
                    text-transform: uppercase;
                }
            }
            // .numero-telephone {
            //     margin: 40px 0 0;
            //     display: inline-block;
            //     padding: 5px 30px;
            //     color: #fff;
            //     background: @brand-primary;
            //     font-size: 18px;
            //     strong {
            //         font-weight: 600;
            //         font-size: 38px;
            //     }
            // }
            // .contactez-nous-suite {
            //     margin-top: 20px
            // }
        }
    }

    // Bloc vous pouvez nous recontacter
    // #block-block-8{
    //     margin-top: 40px;
    // }
    
    // Bloc Agence Haut Rhin
    // #block-block-10{
    //     margin-top: 20px;
    // }
}