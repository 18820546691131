/**
 * lists.less
 */

.site-map-box{

	margin-top: 20px;

	.title{
		display: none;
	}

	a{
		padding-left: 0;
		background-image: none;
	}
	a:not(.btn-primary){
		color: @text-color;
	}

	.content > .site-map-menu{
		padding-left: 0;
		// column-count: 2;
	}
}

.site-map-menu{
	&_item{
		margin-bottom: 20px;
		display: inline-block;
		vertical-align: top;
		width: ~"calc(50% - 6px)";

		.icon-svg{
		}

		@media (max-width: @screen-sm){
			display: block;
			width: auto;
		}

		&:not(.expanded){
			display: block;
		}

		& > a,
		& > .nolink{
			height: auto;
			width: auto;
			text-transform: uppercase;
			font-weight: bold;
			font-size: 1.125em;
			display: block;
		}
		& > a:not(.btn-primary),
		& > .nolink:not(.btn-primary){
			color: @brand-primary;
		}
	}
}