/**
 * c_list-prog-macarons.less
 */

.list-prog-macarons{
	font-size: 0;
	text-align: right;
	padding-left: 40px;
	margin-top: 115px;
}

.prog-macaron{

	&_item{
		text-decoration: none;
		display: inline-block;
		vertical-align: top;
		margin: 5px;
		height: 54px;
	}

	&_img,
	&_imgHover{
		height: 100%;
		width: auto;
	}

	&_imgHover{
		display: none;
	}

	&_item:hover,
	&_item:active,
	&_item:focus{
		text-decoration: none;
	}

	&_item:hover &_imgHover,
	&_item:active &_imgHover,
	&_item:focus &_imgHover{
		display: inline;
	}

	&_item:hover &_img,
	&_item:active &_img,
	&_item:focus &_img{
		display: none;
	}
}