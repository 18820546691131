/**
 * c_blocks.less
 */

// Block Appelez nous page programme
.block-block--phone-programme{
	
	.btn-phone{
		text-align: center;
	}

}

.block-block--scroll-top{
	padding-right: @grid-gutter-width;
	padding-left: @grid-gutter-width;
	padding-bottom: @grid-gutter-width;
	text-align: right;
	margin-top: -60px;
	position: relative;
}

.block-block--header-list-programs{
	h1{
		font-size: 2.57rem;
	}
	p{
		font-size: 1.14rem;
	}
}

// block-block-9 : c'est le block sur la page contact
.block-block--contact{

	.block__content{
		margin-top: @grid-gutter-width;
		text-align: center;
		margin-bottom: 3*@grid-gutter-width;
		font-size: 1.2857em;
		line-height: 1.5em;
		
	}

	.btn-phone_main:before{
		display: none;
	}
}

ul li{
	display: inline-block;
}