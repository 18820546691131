/**
 * animations.less
 */

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(359deg);
	}
}

// Plus into Minus
@keyframes plus_into_minus1{
	0% {
		opacity: 1;
		transform: rotateZ(0);
	}
	100% {
		opacity: 0;
		transform: rotateZ(180deg);
	}
}
@keyframes plus_into_minus2{
	0% {
		transform: rotateZ(0);
	}
	100% {
		transform: rotateZ(180deg);
	}
}