/**
 * body-in-iframe-in-modal.less
 */

body.js-body-in-iframe-in-modal{
	background-color: transparent;
	color: white;

	.page-header{
		color: white;
	}

	#navbar,
	.tabs--primary,
	.footer_top,
	#footer_wrapper,
	#toolbar_region {
	    display: none;
	}

	form .webform-component--mentions-legales, 
	form .webform-component--mentions-legales{
		color: inherit;
	}

	.btn-default,
	.btn-primary{
		background-color: white;
		color: @brand-primary;

		&:hover,
		&:active,
		&:focus{
			background-color: fadeout(white, 10%);
		}
	}

	#simplemeta-meta-form-ajax-wrapper{
		display: none;
	}

}