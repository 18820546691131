.page-home {
    background-color: white;

    #accueil_section_recherche_programme {
        #panel-bootstrap-region-recherche_programme {
            // .tpz_main_container;
            background-color: white;
            margin-bottom: 0px;
            padding: 0px 15px;
            background-color: transparent;
        }
    }
    #accueil_section_categories_programme {
        padding: 0 0;
       .tpz_block_programmes_similaires;
       background-color: transparent;
    }
    #accueil_section_slider_programmes {
        background: @taupe;
        .view-topaze-slider-home {
            color: #fff;
            a {
                color: #fff;
            }
        }
        .views-field {
            margin: 0 25px;
            @media screen {
                @media (min-width: @screen-md-min) {
                    margin: 0;
                }
            }
        }
        .views-field-field-programme-cat-principale {
            display: inline-block;
            margin-top: 20px;
            font-size: @font-size-h3;
            padding: 4px;
            background: #fff;
            color: @brand-primary;
            text-transform: uppercase;
            font-size: @font-size-small;
            font-weight: 700;
            
            @media screen {
              @media (min-width:@screen-md-min) {
                margin-top: 80px;
              }
            }
        }
        .views-field-title,
        .views-field-field-nom-programme {
            margin-top: 25px;
            text-transform: uppercase;
            font-size: @font-size-h2;
            font-weight: 300;
            line-height: 1;
        }
        .views-field-field-programme-ville {
            text-transform: uppercase;
            font-size: @font-size-h2;
            font-weight: 600;
            line-height: 1;
        }
        .views-field-body,
        .views-field-field-prog-slogan {
           margin-top: 10px;
           font-weight: 300;
           text-align: justify;
            @media screen {
              @media (min-width:@screen-md-min) {
                margin-top: 30px;
              }
            }
        }
        .views-field-view-node {
            margin-top: 20px;
            margin-bottom: 25px;
            display: inline-block;
            padding: 4px 15px;
            font-weight: 700;
            text-transform: uppercase;
            background: @brand-primary;
    	    float: right;
	    margin-bottom: 60px;
        }
        .views-field.views-field-field-img-header {
            margin: 0;
        }
        .view-id-topaze_slider_home {
            .item {
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }

        .carousel-indicators {
            @media screen {
              @media (min-width:@screen-md-min) {
                margin-left: -45%;
              }
              
            }
            li {
                background: #fff;
                &.active {
                    background: @grisbrun;
                    border-color: @grisbrun;
                }
            }
        }
    }
    #accueil_section_etapes_top_zen {
        padding: 60px 0 20px;
        background: #fff;
        #panel-bootstrap-row-main {
            font-size: @font-size-h3;
        }
        #panel-bootstrap-row-1 {
            margin-top: 25px;
            .nav li {
                display: inline-block;
                vertical-align: top;
                width: 100%;
                margin: 0 1% 20px;
                text-align: center;
                text-transform: uppercase;
                font-size: @font-size-h4;
                font-weight: 600;
                @media screen {
                    @media (min-width:@screen-sm-min) {
                        width: 46%;
                    }
                    @media (min-width:@screen-md-min) {
                        width: 31%;
                    }
                    @media (min-width:@screen-lg-min) {
                        width: 18%;
                        &.first {
                            margin-left: 0;
                        }
                        &.last {
                            margin-right: 0;
                        }
                    }
                }
                a {
                    color: #fff;
                    padding: 70px 0 75px;
                    background-color: @brand-primary;
                    background-position: 50% 25px;
                    background-repeat: no-repeat;
                    &:hover {
                        background-color: @taupe;
                    }
                }
            }
        }
    }
    #accueil_section_video_top_zen {
        padding: 40px 0;
        background: @body-bg;
        position: relative;
        .bonhomme{
            display: none;
            @media screen and (min-width:1600px){
                display: block;
                position: absolute;
                top: -200px;
                right: 20px;
            }
        }
        .pane-video-etapes-top-zen {
            .embed-container {
                box-shadow: 5px 5px 10px 0 rgba(0,0,0,0.2);
                margin-bottom: 1.5em;
                @media screen and (min-width:@screen-md-min){
                    margin-bottom: 0;
                }
            }
            h2 {
                font-size: @font-size-h2;
                font-weight: 600;
                text-transform: uppercase;
            }
            h3 {
                font-size: @font-size-large;
                font-weight: 300;
            }
            ol {
                .tpz_ol;
            }
        }
    }
}

/* refonte home 2018.07 */

.front{
    /* moteur de recherche */
    #block-moteur-de-recherche-block{
        // @media (min-width: @screen-sm){ // min 768
        //     height: 768px;
        // }
        // @media (min-width: 1600px){ // min 1200
        //     height: 900px;
        // }
        // height: 100%;
        // display: flex;
        // justify-content: center;
    }
}

    
.block-ref{
    margin:50px 0;
    *{
        font-size: 16px;
        color: grey;
    }
}

.wrapper-moteur-de-recherche-block{
    position: relative;
    margin-top: 0;
    
    .wrapper-readmore{
        // position: absolute;
        text-align: center;
        // bottom: 40px;
        width: 100%;
        .btn-readmore{
            &:extend(.btn);
            &:extend(.btn-primary);
            &:extend(.btn-lg);
            text-decoration: none;
            font-weight: 700;
            font-size: (18px / @font-size-base)*1em;
            // transition: all 0.3s;
            // text-align: center;
            // background-color: @brand-primary;
            // border: 1px solid @brand-primary;
            // color: #fff;
            // padding: 10px 40px;
            // &:hover{
            //     color:@brand-primary;
            //     background-color:#fff;
            // }
        }
    }

    // @media (min-width: @screen-sm){ // min 768
    //     margin-top: 100px;
    // }
}    

.section-sliders{
    margin-top: @grid-gutter-width;
    flex-direction: column;  
    
    @media (min-width: @screen-sm){
        flex-direction: row;
        display: flex;

        &__col{
            display: flex;
        }
    }
    @media (min-width: @screen-md){
        &__col{
            padding-right: @grid-gutter-width;
        }
    }
}

.slider-homepage,
.slider-corpo-homepage{
    .slick-prev, 
    .slick-next {
        bottom:25px;
        height: 40px;
        width: 40px;
        background: #fff;
        top:inherit;
        z-index: 1;
        right: 15px;
    }
    .slick-prev{
        bottom: 0;
        left: 0;
        transform: rotate(-90deg);

        &:before {
            content: url(../img/arrow.png);
        }
    }
    .slick-next{
        transform: rotate(90deg);
        right: 0;
        bottom: 0;
        &:before {
            content: url(../img/arrow.png);
        }
    }
    @media (min-width: @screen-sm){
        &.slick-slider{
            display: flex;
            width: 100%;
        }

        .slick-list{
            display: flex;
            width: 100%;
        }

        .slick-track{
            display: flex;
        }

        .slick-slide{
            display: flex;
            
        }
    }
    @media (min-width: @screen-md){

    }
}

.wrapper-slide{
    position: relative;
    display: flex;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    // je met pas height:0 au cas ou la hauteur de wrapper-slide-content
    // depasse la hauteur de wrapper-slide (sur mobile particulierement)
    // height: 0;
    padding-top: (920 / 1200)*100%;

    .wrapper-slide-content{
        // position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        background-color: rgba(70, 160, 222, 0.8);
        width: 100%;
        padding: @grid-gutter-width 50px 50px @grid-gutter-width;
        
        *{
            color: #fff;
        }
        h2{
            font-size: (28px / @font-size-base)*1em;
            font-weight: bold;
        }
        p{
            font-size: 14px;
        }
    }

    @media (min-width: @screen-sm){
        padding-top: 0;
        height: auto;
        .wrapper-slide-content{
            position: absolute;
            h2{
                font-size: (46px / @font-size-base)*1em;
            }
        }
    }
    @media (min-width: @screen-md){
        .wrapper-slide-content{
            width: 75%;
        }
    }
}

.wrapper-thematiques{
    .wrapper-theme{
        margin-top: 0.5*@grid-gutter-width;
        margin-bottom: 0.5*@grid-gutter-width;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        transition: all 300ms ease-in-out;
        // *{
        //     color: #fff;
        // }
        a{
            display: block;
            text-decoration: none;
            color: white;
            position: relative;
            width: 100%;
            overflow: hidden;
            background-color: rgba(70, 160, 222, 0.6);
        }
        h2, .h2{
            font-size: (20px / @font-size-base)*1em;
            margin-top: 0;
            transition: all 0.3s;
            display: block;
            width: 100%;
            & > span{
                font-size: 2em;
                text-transform: uppercase;
                font-weight: bold;
                display: block;
            }
        }
        p{
            font-size: (16px / @font-size-base)*1em;
        }
    }
    .readmore{
        background-color: #ffffff;
        color: @brand-primary;
        font-size: 16px;
        font-weight: 700;
        padding: 15px 30px;
        text-align: center;
        display: inline-block;

    }
    .wrapper-theme-content,
    .wrapper-theme-content--hover{
        width: 100%;
        padding: 1.5*@grid-gutter-width @grid-gutter-width;
    }
    .wrapper-theme-content{
        text-align: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: none;
        transition: all 250ms ease-in;
    }
    .wrapper-theme-content--hover{
        text-align: left;
        transform: scale(1);
        visibility: visible;
        transition: all 450ms ease-in-out;
        .h2{
            font-size: (16px / @font-size-base)*1em;
        }
    }

    @media (min-width: @screen-sm){
        .wrapper-theme{
            margin-top: 0;
            margin-bottom: 0;
            a{
                background-color: rgba(0, 0, 0, 0.6);
            }
            &:hover{
                a{
                    background-color: rgba(70, 160, 222, 0.8);
                }
                .wrapper-theme-content{
                    opacity: 0;
                    visibility: hidden;
                }
                .wrapper-theme-content--hover{
                    transform: scale(1);
                    opacity: 1;
                    // visibility: visible;
                }
            }
        }
        .wrapper-theme-content{
            display: flex;
            align-items: center;
            visibility: visible;
        }
        .wrapper-theme-content--hover{
            transform: scale(0.8);
            opacity: 0;
            // visibility: hidden;
        }
    }
    @media (min-width: @screen-md){
        .wrapper-theme{
            h4{

            }
        }
        .wrapper-theme-content{

        }
    }

    @media (min-width: @screen-lg){
        .wrapper-theme{
            &:hover{
                h4{
                    margin-top: 0;
                }
            }

        }
    }
}

.section-actus{
    padding-top: 4*@grid-gutter-width;
    padding-bottom: 4*@grid-gutter-width;
    .container{
        padding-left: 0;
        padding-right: 0;
    }
    h2{
        color: #252525;
        text-align: center;
        font-size: 48px;
        font-weight: 700;
        margin: 30px 0;
    }
    .view-footer{
        a{
            display: block;
            text-align: center;
            padding: 30px 0;
            clear: both;
        }
    } 
}


ul.actu{
    padding:0;
    margin:0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li{
        margin: 0 0 30px;
        display: flex;
    }
    
    [class*="col-"]{
        padding-right: 0.5*@grid-gutter-width;
        padding-left: 0.5*@grid-gutter-width;
    }
    .views-field{
        display: flex;
        width: 100%;
    }
    .field-content{
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .wrapper-actu{
        box-shadow: 0 0 57px rgba(0, 0, 0, 0.17);
        max-width: 400px;
        width: 100%;
        transition: all 0.3s;
        display:block;
        margin: 0;
        position: relative;
        background:#fff;
        padding-bottom: @grid-gutter-width + 34px + @grid-gutter-width;
        img{
            &:extend(.img-responsive);
        }
        &:hover{
            transform: scale(1.05); 
        }
    }

    .type-actu {
        background-color: @brand-primary;
        position: absolute;
        top:12px;
        right: 15px;
        text-transform: uppercase;
        font-size: 13px;
        color: #fff;
        padding: 2px 10px;
    }
    .date-actu{
        color: @brand-primary;
        font-size: 14px;
        text-align: center;
        font-weight: 700;
        margin-top: 15px;
    }
    .views-more-link{
        &:extend(.btn);
        &:extend(.btn-primary);
        display: block;
        position: absolute;
        bottom: @grid-gutter-width;
        left: 2*@grid-gutter-width;
        right: 2*@grid-gutter-width;
    }
    p{
        font-size: (16px / @font-size-base)*1em;
        padding-right: @grid-gutter-width;
        padding-left: @grid-gutter-width;
        padding-bottom: 1.5*@grid-gutter-width;
        color: @text-color;
        a{
            color: @text-color;
        }
    }
    h3{
        text-align: center;
        font-weight: 700;
        padding: 0 40px;
        color: #000000;
        font-size: (18px / @font-size-base)*1em;
        margin-top: 5px;
    }
    a:hover{
        text-decoration: none;
    }
}


.block-corpo{
    &__col{

    }
    &__left{
        width: 100%;
    }
    .wrapper-video-yt{
        width: 100%;
        overflow: hidden;
        position: relative;
        &:before{
            content: "";
            display: block;
            padding-bottom: (9 / 16)*100%;
        }
        iframe{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }
    }

    &__right{
        padding: 2*@grid-gutter-width 0;
        background-image: url("../img/bg-form-actualite.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-color: @brand-primary;
        color: #ffffff;
        h2{
            font-size: (30px / @font-size-base)*1em;
            margin-bottom: (50px / 30px)*1em;
            text-transform: uppercase;
            font-weight: 700;
            margin-top: 0;
        }
        h3{
            font-size: (18px / @font-size-base)*1em;
            font-weight: 700;
            @media (min-width: @screen-sm){ // min 768
                font-size: 24px;
            }
        }
        p{
            font-size: 16px;
        }
    }
    .liste-icones{
        display: inline-block;
        text-align: center;
    }
    .wrapper-icone{
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        padding: 20px;
        width: 50%;
        min-width: 175px;
        word-break: break-all;
        white-space: normal;
        
        img{
            margin-bottom: 10px;
            display: inline-block;
        }
        p{
            font-size: 18px;
            font-weight: 700; 
        }
    }
    .wrapper-corpo-content{
        text-align: center;
        padding: 0 30px;
        @media (min-width: 1400px){ // min 1200
            padding: 0 80px;
        }
    }

     @media (min-width: @screen-sm){
         display: flex;
         flex-direction: row;

         &__col{
            display: flex;
         }
         &__left{
             display: flex;
         }
         .wrapper-video-yt{
            width: 100%;
            padding-bottom: 0;
            height: auto;
        }
     }

    @media (min-width: @screen-lg){ // min 768
        &__right{
            padding: 40px;
        }
        .wrapper-icone{
            width: 25%;
        }
    }

}

.slider-corpo-homepage{
    .slick-arrow{
        bottom: 2*@font-size-base;
    }
    .slick-prev{
        left: 1.5*@grid-gutter-width;
    }
    .slick-next{
        right: 1.5*@grid-gutter-width;
    }
}

.block-temoignages{
    background:#fff;
    h2{
        font-size: 32px;
        font-weight: 700;
        text-align: center;
        color: #252525;
        padding: 40px 0;
        @media (min-width: @screen-sm){ // min 768
            font-size: 48px;
            padding: 60px 0;
        }
    }
    .view-content{
        display: flex;
        flex-wrap: wrap;
    }
    .views-row{
        display: block;
        float: left;
        width: 100%;
        position: relative;
        padding: 14px 0.5*@grid-gutter-width 40px (65px + 0.5*@grid-gutter-width);
        font-size: 16px;
        
        &:before{
            background: url("../img/quote_open.png") 0% 100% no-repeat;
            position: absolute;
            left: 0.5*@grid-gutter-width;
            top: 0;
            content:"";
            width: 50px;
            height: 35px;
        }
        .views-field-title{
            font-style: italic;
            margin-top: 15px;
        }
        .views-field-created{
            color: @brand-primary;
            font-weight: 700;
            padding: 5px 0 15px;
        }
    }
    @media (min-width: @screen-sm){
        .views-row{
            width: 50%;

        }
    }
}