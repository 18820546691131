/**
 * c_recherche-programme
 */

.c_recherche-programme{
    .clearfix();
    &:extend(.container);
    font-size: 1em;
    box-shadow: 0 0 12px 3px fadeout(black, 70%);
    border-top:5px solid @brand-primary;
    background-color: rgba(0, 0, 0, 0.5);
    margin-bottom: @grid-gutter-width;

    .form-control{
        font-size: 16px;
        border:0;
    }

    form::-moz-placeholder,
    form::-ms-input-placeholder,
    form::-webkit-input-placeholder {
        color: #69625b !important;
        font-style: normal;
        height: 16px !important;
        opacity: 1 !important;
    }

    .views-exposed-form{
        padding-top: 2*@grid-gutter-width;
        padding-right: @grid-gutter-width;
        padding-bottom: 2*@grid-gutter-width;
        padding-left: @grid-gutter-width;
        position: relative;
    }
    .views-exposed-widgets{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .views-exposed-widget{
        padding-left: 0.5*@grid-gutter-width;
        padding-right: 0.5*@grid-gutter-width;
        padding-top: 0.5*@grid-gutter-width;
        padding-bottom: 0.5*@grid-gutter-width;
        width: (1/3)*100%;
        min-width: 250px;
        max-width: 350px;

        .form-submit{
            margin-top: 0;
        }
    }
    .views-submit-button{
        width: 100%;
        max-width: none;
        text-align: center;
    }

    .form-item-combine{
        position:relative;
        .form-control{
            padding: 6px 12px 6px 30px;
        }
        &:before{
            position:absolute;
            left: 11px;
            top: 9px;
            content: url(../img/picto_mdr_gmap.png);
        }
    }
    .form-item-distance{
        position:relative;
        font-style: italic;
        color: #bbb3ae;
        .form-control{
            padding: 6px 12px 6px 30px;
        }
        &:before{
            position:absolute;
            left: 8px;
            top: 11px;
            content: url(../img/picto_mdr_car.png);
        }
    }
    #edit-distance-wrapper{
        & > label{
            display: none;
        }
    }
    
    .form-item-field-ville-du-programme-tid{
        .dropdown-menu{
            li{
                display: block;
            }
        }
    }

    .form-submit{
        &:extend(.btn);
        &:extend(.btn-primary);
        background-color: @brand-primary;
        transition: all 0.3s;
        font-size: (18px / @font-size-base)*1em;
        font-weight: 700;
        position: relative;
        &:before{
            content: url(../img/icon-search.png);
            display: inline-block;
            vertical-align: middle;
            margin-right: 0.5*@grid-gutter-width;
            margin-top: 0.15em;
        }
    }

}
