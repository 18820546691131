/**
 * Slick.less
 * @desc : fichier d'import du vendors slick
 */

@import "vendors/slick/slick-theme.less";
@import "vendors/slick/slick.less";



.slick-initialized{
    .slick-slide{
        text-decoration: none;
    }
}