.vocabulary-villes-programmes{
    .header-ville{
        margin-top: 50px;
        height: 232px;
        @media (min-width: @screen-md) { //min 992
            height:368px;                            
        }    
    }
    h1{
        color:#fff;
        font-size: 40px;
        font-weight: 700;
        text-align: center;
        padding-top: 50px;
        @media (min-width: @screen-md){ // min 768
            margin: 40px 0;
            padding-top: 150px;
        }
    }
    h2{
        font-weight: 400;
        text-align: center;
        color:#fff;
        font-size: 30px;
    }
    .taxonomy-term-description,
    .field-name-field-description-2{
        font-size: 20px;
        color: #2a2a2a;
        margin: 20px 0;
        @media (min-width: @screen-sm){ // min 768
            margin: 40px 0;
        }
    }
}

.list-programmes{
    .block-title{
        display: none;
    }
    h2{
        color: #252525;
        font-weight: 700;
        font-size: 20px;
        text-align: left;
        margin:0;
    }
    .views-row{
        width:100%;
        display: inline-block;
        vertical-align: top;
        @media (min-width: @screen-sm){ // min 768
            width: 45%;
        }
    }
    .wapper-list-programme{
        background-color: white;
        padding: 1.5em !important;
        box-shadow: 0px 3px 15px -3px rgba(0,0,0,0.5);
        margin: 20px;
        a{
            text-decoration: none;
        }
    }
    .views-field-title{
         .field-content{
            font-size: 16px;
            font-weight: 600;
            display:block;
            text-align:center;
            color: #252525;
            font-size: 16px;
            height: 54px;
            text-transform: uppercase;
            &:after{
                content: "_";
                display: block;
                margin: -10px 0 10px;
            }
        }
    }
    .views-field-nothing-2{
        .field-content{
            text-align: center;
            padding: 6px 12px;
            font-size: 14px;
            display: block;
            width: 100%;
            background:@brand-primary;
            color: #fff;
            font-weight: 700;
            margin-top: 20px;
            text-transform: uppercase;
        }
    }
}