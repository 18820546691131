/**
 * glyphicons.less
 */

// AJAX "Throbber".
.ajax-progress {
	.glyphicon {
		font-size: 90%;
		margin: 0 -.25em 0 0.5em;
	}
}

.glyphicon-spin {
	display: inline-block;
	animation: spin 1s infinite linear;
}

a .glyphicon-spin {
	display: inline-block;
	text-decoration: none;
}

.glyphicon-refresh {
	transform-origin: 50% 45%;
}