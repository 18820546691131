/**
 * c_btn.less
 */

.btn-secondary{
	.button-variant(@brand-primary, white, transparent);
}

.btn-phone{
	background: transparent;
	border: none;
	outline: 0;
	border: solid 3px;
	padding: 10px 20px;
	overflow: hidden;
	position: relative;
	background-color: @brand-primary;
	color: white;
	font-weight: bold;

	a[href^="tel:"]{
		color: inherit;
		font-weight: bold;
	}

	&__main{
		&:before{
			content: "";
			width: 22px;
			height: 22px;
			background-image: url(/sites/all/themes/topaze/img/toolbar/tel.png);
			background-position: center center;
			background-repeat: no-repeat;
			display: inline-block;
			vertical-align: middle;
			margin-right: 10px;
			background-size: cover;
		}
	}
	&.btn-sm &__main:before{
		width: 15px;
		height: 15px;
	}

	// &_toreveal{
	// 	left: 0;
	// 	top: -100%;
	// 	height: 0;
	// 	width: 100%;
	// 	transition: all 0.3s;
	// 	position: absolute;
	// 	overflow: hidden;
	// 	font-weight: normal;

	// 	div:last-child{
	// 		font-size: 2em;
	// 	}
	// }

	// &.active{
	// 	border: none;
	// }

	// &.active &_toreveal{
	// 	height: 100%;
	// 	position: static;
	// }

	// &.active &_main{
	// 	transform: translateY(300%);
	// 	display: none;
	// }
}

.btn-mail{
	&:before{
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 1em;
		height: 1em;
		background-image: url(../img/svg/envelope.svg);
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;

	}
}

.btn-anchor{
	display: block;
    padding: 16px 16px;
    background-color: @taupe;
    color: white;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    max-width: 365px;

    & + &{
    	margin-top: 16px;
    }

    &:hover,
    &:active,
    &:focus {
    	background-color: @brand-primary;
    	color: white;
    }
}

.btn-ghost{
	background-color: transparent;
	color: @brand-primary;
	border-width: 3px;
	border-style: solid;
	display: inline-block;
	font-weight: bold;

	&:hover,
	&:active,
	&:focus{
		text-decoration: none;
	}
	

	& + &{
		margin-top: 17px;
	}

	&.btn-mail{
		&:before{
			background-image: url(../img/svg/envelope--primary.svg);
			
		}
	}

	.btn-phone__main{
		&:before{
			background-image: url(../img/svg/phone--primary.svg);
			
		}	
	}
}

.btn-s-medium{
	float: none;
	font-weight: bold;
	padding: @grid-gutter-width 2*@grid-gutter-width;
	font-size: 1.28571428571em;
	text-align: center;
	text-transform: uppercase;
	white-space: normal;
}

.btn-s-square{
	width: 44px;
	height: 44px;
	font-size: 0;
	padding-top: 0;
	padding-bottom: 0;
	border: 0;

	.icon-svg{
		line-height: 50px;
		height: 100%;
	}
}


