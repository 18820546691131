.page-mes-programmes-favoris {
  .container {
    margin: 0 auto;
  }

  #page_section_contenu {
    #region_node_page_contenu {
      .tpz_main_container;
      h1 {
        text-transform: uppercase;
        font-weight: 300;
        color: @brand-primary;
        strong {
          font-weight: 600;
          color: @gray-dark;
        }
      }
      .pane-1 {
        .tpz_anchors_block;
        ul {
          margin: 0;
        }
      }
      .pane-2 {
        font-size: @font-size-h2;
        color: @gray;
        font-weight: 600;
      }
      .pane-3 {
        font-size: @font-size-h2;
        font-weight: 300;
        color: @brand-primary;
        text-transform: uppercase;
        line-height: 100%;
        @media screen {
          @media (min-width:@screen-md-min) {
            font-size: @font-size-h1;
          }
        }
      }
      .pane-4 {
        font-size: @font-size-h2;
        font-weight: 600;
        color: @gray-dark;
        text-transform: uppercase;
        line-height: 100%;
        @media screen {
          @media (min-width:@screen-md-min) {
            font-size: @font-size-h1;
          }
        }
      }
      .view-id-flag_bookmarks {
        .view-empty {
          margin-top: 2em;
        }
      }
    }
  }
}
