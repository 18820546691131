#toolbar_region {
	display: none;
	width: 35px;
	overflow: hidden;
	
	@media screen {
		@media (min-width:@screen-sm-min) {
		  display: block;
		  position: fixed;
		  top: 30%;
		  right: 0;
		  z-index: @zindex-navbar;
		}
	}

	@media (min-width:@screen-lg-min) {
		width: 55px;
	}
}

.block-topaze-toolbar {
	// transform: translateX(100%);
	// left: -35px;
	position: relative;

	h2 {
		display: none;
	}

	@media (min-width:@screen-lg-min) {
		// transform: translateX(100%);
		// left: -55px;
	}

}

.topaze-toolbar_list{
	padding: 0;
	margin-bottom: 0;
	
	li {
		list-style-type: none;
		background: @brand-primary;
		margin-bottom: 1px;
		transition: transform 300ms ease-in-out;
		position: relative;
		width: 175px;
		
		&:hover,
		&:focus{
			// transform: translateX(-175px + 35px);
		}
	}

	a {
		display: block;
		height: 35px;
		color: white;
		padding-right: 10px;
		&:hover,
		&:focus,
		&:active {
			// background-color: @c-blue_darker;
			background-color: @taupe;
		}

		&:before{
			content: "";
			display: inline-block;
			vertical-align: middle;
			width: 35px;
			height: 100%;
			background-position: center center;
			background-repeat: no-repeat;
			// background-color: red;
			margin-right: 10px;
		}	
	}

	@media (min-width:@screen-lg-min) {
		li:hover,
		li:focus{
			// transform: translateX(-175px + 55px);
		}
		a{
			height: 55px;
			&:before{
				width: 55px;
				margin-right: 0;
			}
		}
	}


	.tel a:before { background-image: url(../img/toolbar/tel.png);}
	.contact a:before { background-image: url(../img/toolbar/contact.png);}
	.bookmarks a:before { background-image: url(../img/toolbar/bookmarks.png);}
	.facebook a:before { background-image: url(../img/toolbar/facebook.png);}
	.linkedin a:before { background-image: url(../img/toolbar/linkedin.png);}
	.twitter a:before {	background-image: url(../img/toolbar/twitter.png);}
	.print a:before { background-image: url(../img/toolbar/print.png);}


}