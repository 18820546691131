.page-parrainage,
.page-topaze-et-vous-offre-parrainage {
  .container {
    margin: 0 auto;
  }

  #page_section_contenu {
    #region_node_page_contenu {
      .tpz_main_container;
      .pane-contenu-page-parrainage {
        .tpz_main_container_inside;
      }
      #anchors_block {
        .tpz_anchors_block;
        ul {
          float: left;
          margin: 0;
          padding: 0;
          li {
            margin-bottom: 16px;
          }
        }
      }
      #panel-bootstrap-column-main-column {
        @media screen {
          @media (min-width:@screen-md-min) {
            padding-right: 50px;
          }
        }
        margin-bottom: 15px;
        .pane-1 {
          margin-bottom: 50px;
          .pane-title {
            .tpz_titre_rubrique;
          }
        }
        .pane-block {
          p:first-child {
            text-transform: uppercase;
          }
        }
      }
      #panel-bootstrap-column-1 {
        @media screen {
          @media (min-width:@screen-md-min) {
            padding-left: 50px;
          }
        }
        .pane-2 {
          margin-top: -15px;
          .clearfix();
        }
        .pane-3 {
          text-align: left;
          .pane-title {
            font-size: @font-size-h1;
            text-transform: uppercase;
          }
          .pane-content {
            display: none;
            .btn-default {
              float: none;
              padding: 8px 20px;
              display: inline-block;
              
            }
          }
        }
      }
    }
  }
}
