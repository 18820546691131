.page-investir-avec-topaze {
  .container {
    margin: 0 auto;
  }

  #page_section_contenu {
    #region_node_page_contenu {
      padding: 0px 15px;
    }
  }
}
