//node de type actualité
.node-type-actualites{
    .date-actu{
        color: @brand-primary;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 30px;
    }
    .page-header{
        font-size: 40px;
        color: #252525;
    }
    .breadcrumb{
        margin-top: 95px;
        @media (min-width: @screen-sm){ // min 768
            margin-top: 185px;
        }
    }
    .col-xs-16{
        @media (min-width: @screen-sm){ // min 768
            padding: 0 10px !important;
        }
    }
    p{
        font-size: (16px / @font-size-base)*1em;
        color: #252525;
    }
    p.rtecenter{
        color: #fff;
    }
    .articles-similaires{
        h3{
            font-size: 20px;
            font-weight: 700;
            color: #252525;
            border-bottom: 1px solid #252525;
            padding-bottom: 10px;
            margin: 30px 0 20px;
        }
        a{
            display: block;
            margin: 15px 0;
            background: none;
            transition: all 0.3s;
            &:hover{
                background: #fff;
            }
        }
        div{
            display: inline-block;
            vertical-align: middle;
        }
        .wrapper-articles-similaires{
            font-size: 16px;
            color: #252525;
            font-weight: 700;
            margin-left:25px;
            transition: all 0.3s;
            width: 60%;
            @media (min-width: @screen-sm){ // min 768
                width: 68%;
            }
            @media (min-width: @screen-sm){ // min 768
                &:hover{
                    margin-left:30px;
                }
            }
            span{
                color: @brand-primary;
                font-size: 14px;
                display:block;
            }
        }
    }
    .list-medias{
        h3{
            color: #252525;
            font-weight: 700;
        }
        .line-doc{
            background: url("../img/icon-pdf.png") 0% 50% no-repeat;
            display: inline-block;
            width: 100%;
            padding: 20px 0 20px 60px;
            color: #252525;
            text-decoration:none;
            font-weight: 400;
            font-size: 16px;
            &:hover{
                color: @brand-primary;
                background: url("../img/icon-pdf_hover.png") 0% 50% no-repeat;
            }
        }
    }
    .content{
        margin: 30px 0;
    }
    aside{
        .node-webform{
            background: url("../img/bg-form-actualite.png") 0% 100% no-repeat;
            background-color: #46a0de;
            padding: 0 30px 30px 30px;
            color:#fff; 
            h2 a{
                font-size: 20px;
                font-weight: 700;
                color:#fff;
            }
            .webform-component--mentions-legales{
                color:#fff;
            }
            p{
                font-size: 1rem;
                color:#fff;
            }
            .alert-success, .webform-confirmation{
                background-color: inherit;
                border-color: none;
                color: @brand-primary;
                a{
                    color: #fff;
                }
            }
        }
    }
}