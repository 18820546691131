//listing actualités
.page-actualite-immobilier{
    .page-header{
        margin-top: 105px;
        text-align: center;
        font-size: 40px;
        border-bottom:none;
        color: #292929;
    }
    .view-header{
        font-size: 20px;
        font-weight: 400;
        color: #2a2a2a;
        padding: 40px 0;
    }
    .views-exposed-form{
        padding-bottom: 40px;
        .views-exposed-widget{
            float: none;
            padding-right: 0;
            text-align: center;
        }
        .form-item{
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            a{
                color: @brand-primary;
                display: block;
                border: 1px solid @brand-primary;
                transition: all 0.3s;
                padding:10px 30px;
                text-decoration:none;
                margin : 15px;
                font-size: 20px;
                font-weight: 700;
                &:hover{
                    background:@brand-primary;
                    color: #fff;
                }
            }
        }
    }
    .view-footer{
        clear: both;
    }
}