.container-fluid .footer{
	[class*="col-"]{
		padding-right: @grid-gutter-width;
		padding-left: @grid-gutter-width;
	}
}

.footer_top {
	background: @brand-primary; color: #fff; text-transform: uppercase;
	#block-menu-menu-menu-mentions-legales {
		li {
			display: inline-block;
			font-size: 15px;
			text-align: center;
			width: 100%;
		    @media screen {
				@media (min-width:@screen-md-min) {
					text-align: left;
					width: auto;
				}
			}
			a {
				margin: 0;
				color: #fff;
			    @media screen {
					@media (min-width:@screen-md-min) {
						margin: 20px 0;
					}
				}
				&:hover {
					color: @brand-primary;
				}
			}
		    @media screen {
				@media (max-width:@screen-md-min) {
					span {
						display: block;
						margin: 25px 15px 10px;
					}
				}
			}
			&.open a {
				background: transparent;
				border: 0;
				&:hover {
					background: #fff;
				}
			}
		}
	}
	.block_telephone {
		padding: 25px 0;
		font-size: 22px;
		text-align: center;
		font-weight: 600;

		a[href^="tel:"] {
			color: inherit;
		}
	    @media screen {
			@media (min-width:@screen-md-min) {
				text-align: right;
			}
		}
	}
}
#footer_wrapper {
	background: #fff;
}
.footer {
	border: 0; margin-top: 0; padding: 10px 0;
	[class*="col-"]{
		padding-top: 40px;
	}
	.block-blackangus{
		clear: both;
	}
	.block_telephone{
		.btn-lg{
			display: block;
			width: 100%;
			text-align: left;
		}
	}
    @media screen {
		@media (min-width:@screen-md-min) {
			.block_partenaires, 
			.block_social {
				text-align: right;
				margin-top: 10px;
			}
		}
	}
	.block_social .menu li {
		display: inline-block;
		margin: 5px;
		a {
			width: 24px;
			height: 24px;
			text-indent: 5000px;
			overflow: hidden;
		}
	}
}

.block-footer-fb{
	padding-top: 18px;
	padding-bottom: 18px;
	padding-right: 5px;
	padding-left: 5px;
	font-size: 1.2em;
	a:not([class]){
		color: white;

		&:after{
			content: "";
			display: inline-block;
			vertical-align: middle;
			width: 1.6em;
			height: 1.6em;
			background-image: url(../img/svg/facebook--white.svg);
			background-repeat: no-repeat;
			background-position: center center;
			background-size: contain;
			margin-left: 10px;
		}
	}
}

.block-footer-topaze_address{
	position: relative;

	&.block-footer-topaze_address.block-footer-topaze_address{
		padding-left: 98px + 30px;
	}

	&:before{
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 40px;
		bottom: 0;
		width: 98px;
		background-image: url(../img/logo-noshadow.png);
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		
	}
	p:first-of-type{
		color: @brand-primary;
		text-transform: uppercase;
		font-size: 1.2em;
		font-weight: bold;
	}
}

.block-footer-rencontre{
	text-align: left;

	p:first-of-type{
		margin-bottom: 38px;
		a{
			font-weight: bold;
			text-decoration: underline;
		}
		a:before{
			content: '';
			display: inline-block;
			vertical-align: middle;
			width: 22px;
			height: 22px;
			margin-right: 5px;
			background-image: url(../img/svg/placeholder--primary.svg);
			background-size: contain;
			background-position: center center;
			background-repeat: no-repeat;
			
		}
	}
	p:nth-of-type(n + 2 ){
		margin-left: 10px;
	}
}

.block-menu--footer{
	text-transform: uppercase;
	clear: both;

	.nav{
		text-align: center;
	}

	.nav > li {
		display: inline-block;
		vertical-align: middle;

		&:after{
			content: '|';
			display: inline-block;
			vertical-align: middle;
		}
		&:last-child:after{
			display: none;
		}

		& > a {
			font-weight: bold;
			color: black;
			display: inline-block;
		}
	}
}

.block_topzen{
	clear: both;
}
