/** 
 * inscription-soiree.less  
 */

.page-node-3665{
    #page_section_contenu .pane-node-body{
        padding-top: 2*@grid-gutter-width;
    }
    .pane-node-body{
        display: inline-block;
        width: 50%;
        padding-right: 0.5*@grid-gutter-width;
        margin-right: -3px;
        vertical-align: top;
        
    }
    .pane-webform-client-block-3664{
        display: inline-block;
        width: 50%;
        padding-left: 0.5*@grid-gutter-width;
        margin-right: -3px;
        vertical-align: top;
        padding-top: 0.5*@grid-gutter-width;
        
    }

    @media (max-width: @screen-sm){
        .pane-node-body,
        .pane-webform-client-block-3664{
            padding-right: 0;
            padding-left: 0;
            width: 100%;
            margin-right: 0;
            margin-left: 0;
        }
        .pane-webform-client-block-3664{
            margin-top: 0.5*@grid-gutter-width;
        }
    }
}