.node-type-programme, 
.node-type-programme-location {
  .container {
    margin: 0 auto;
  }

  h1{
    text-align: center;
    font-size: 1.71em;

    span + span{
      display: block;
    }

    @media @tablet{
      text-align: left;
      // .h1();
      font-size: 2.57em;

      span + span{
        display: inline-block;
        white-space: nowrap;
      }
    }
  }

  h2{
    color: @brand-primary;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.71em;

    @media @tablet{
      text-align:  inherit;
    } 
  }

  h3{
    font-size: 1.14em;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 23px;
  }

  .breadcrumb{
    display: none;
    
    @media @tablet{
      display: inline-block;
    }
  }

  .webform-client-form h3{
    font-size: 1.71em;
    // margin-top: 40px;
  }

  .field-name-field-img-header{
    overflow: hidden;
  }

  .field-type-text-long,
  .field-type-text-with-summary {
    h2{
      // font-weight: bold;
      // color: @brand-primary;
      // font-size: 20px;

      // Hack to correct the inline style that they put 
      // in the wisiwyg
      span{
        font-weight: inherit !important;
        color: inherit !important;
        font-size: inherit !important;
      }
    }
  }
  .pane-node-field-accroche {
    min-height: 60px;
    font-size: 1.71em;
    line-height: 1em;
    font-weight: 600;
    font-style: italic;
    color: @gray-dark;
    padding: 0.75*@grid-gutter-width 0.5*@grid-gutter-width 0 85px;
    margin-bottom: 1.5*@grid-gutter-width;
    background: url(../img/quote.png) no-repeat 0 0;
    text-indent: -40px;

    .field-item:after {
      content: ' "';
    }

    @media @tablet {
        font-size: 2.5em;
    }
  }

  .field-name-field-lots-txt{
    margin-bottom: 1.5*@grid-gutter-width;
    text-align: center;
    font-size: 1.14em;
    line-height: 1.6em; 

    h2{
      margin-bottom: 1.5*@grid-gutter-width;
    }
  }

  .field-name-field-txt-residence{
    padding: @grid-gutter-width;

    @media @tablet{
      max-width: 633px;
    }
  }

  .pane-logement-descriptif-programme {
    // .tpz_main_container;
    & > .pane-content {
      width: 100%;
      background-color: white;
      padding: 0.5*@grid-gutter-width 0.75*@grid-gutter-width;

      @media @tablet{
        padding-right: 5*0.5*@grid-gutter-width;
        padding-left: 5*0.5*@grid-gutter-width;
      }
    }
  }
  #programme_section_descriptif {
    .pane-node-title h1 {
      margin: 0;
      text-transform: uppercase;
      color: @brand-primary;
      @media screen {
        @media (max-width: @screen-md-min) {
          font-size: @font-size-h3;
        }
      }
    }
    .pane-node-field-location {
      font-size: @font-size-h2;
      @media screen {
        @media (max-width: @screen-md-min) {
          font-size: @font-size-h3;
        }
      }
      font-weight: 600;
      text-transform: uppercase;
      color: @gray-dark;
    }
    .pane-node-field-programme-cat-principale {
      position: relative;
      display: inline-block;
      vertical-align: top;
      margin: 0 0 15px -54px;
      padding: 25px 30px;
      background: @brand-primary;
      color: #fff;
      text-transform: uppercase;
      font-size: @font-size-large;
      &:after {
        position: absolute;
        bottom: -61px;
        left: 0;
        content: url(../img/banner-left.png);
      }
      @media screen {
        @media (min-width: @screen-md-min) {
          margin-left: -84px;
        }
      }
    }
    .pane-node-field-programme-cat-principale + .panel-separator {
      display: none;
    }
    .pane-flag-link {
      .tpz_flag_bookmark;
    }
    // #panel-bootstrap-column-1 {
    //   display: none;
    //   @media screen {
    //     @media (min-width:@screen-md-min) {
    //       display: block;
    //     }
    //   }
    // }
    #panel-bootstrap-region-top_right li {
      list-style-type: none;
      margin-bottom: 16px;
      a {
        display: block;
        padding: 10px 6px;
        text-align: center;
        text-transform: lowercase;
        font-weight: 700;
        color: #fff;
        background: @gray;
        &:first-letter{
            text-transform: uppercase;
        }
      }
    }
    // .pane-node-body {
    //   @media screen {
    //     // font-size: 16px;
    //     // line-height: 1.5;
    //     @media (min-width:@screen-md-min) {
    //       padding-right: 3*@grid-gutter-width;
    //     }
    //   }
    // }
    .pane-node-field-img-text {
      margin: 0 0 30px;
      img {
    	  .img-responsive();
        margin-right: auto;
        margin-left: auto;

        @media @tablet{
          margin-left: 0;
        }
     }
    }
    .pane-node-field-programme-descriptif-suite {
      margin: 0 0 30px;
      // @media screen {
      //   @media (min-width:@screen-md-min) {
      //     font-size: 16px;
      //     line-height: 1.5;
      //   }
      // }
      ul {
        .tpz_ul;
      }
    }
    #mini-panel-logement_prix_programme {
      margin: 50px 0 0 10px;
      padding: 30px 40px;
      background: @brand-primary;
      color: #fff;
      font-weight: 600;
      .pane-node-field-prix-min { font-size: @font-size-h3; }
      .pane-1 {
        font-size: @font-size-h4;
        font-weight: 700;
        margin-bottom: 20px;
      }
      .pane-2 { font-size: @font-size-h3; }
      @media screen {
        @media (min-width:@screen-md-min) {
          margin: 50px -90px 0 0;
          .pane-node-field-prix-min { font-size: 40px; }
        }
      }
    }
    .pane-logement-evenement-programme {
      #panel-bootstrap-column-2 {
        width: 0;
      }
      #panel-bootstrap-column-3 {
        margin: 20px auto;
        padding-top: 30px;
        padding-bottom: 30px;
        text-align: center;

        @media screen {
          @media (min-width:@screen-md-min) {
            width: 80%;
            padding-right: 20px;
            padding-left: 20px;
            text-align: left;
          }
        }
      }
      .pane-node-field-programme-evt-offre, .pane-node-field-programme-evt-offre-date {
        background: @brand-primary;
        color: #fff;
        font-size: @font-size-h4;
        font-weight: 600;
        padding: 0 30px;
        text-transform: uppercase;
      }
      .pane-node-field-programme-evt-offre {
        padding-top: 10px;
      }
      .pane-node-field-programme-evt-offre-date {
        padding-bottom: 10px;
      }
      .pane-5 {
        font-size: @font-size-h4;
        font-weight: 600;
        text-transform: uppercase;
        padding: 5px 30px;
      }
    }
  }
  #programme_section_lots {
    // padding: 30px 0;
    background: #fff;

    .field-name-field-lots-txt{
      padding-top: 30px;
    }

    .pane-views{
      padding-bottom: 30px;
    }

    .pane-title {
      font-size: @font-size-h1;
      font-weight: 600;
      text-transform: uppercase;
    }
    #panel-bootstrap-region-les_types_de_logements {
      position: relative;
    }
    .acceder-aux-simulateurs, 
    .menu-calculettes {
      /*display: none;*/
      position: absolute;
      right: 10px;
    }
    .menu-calculettes {
      display: none;
      top: 60px;
      width: 310px;
      background: #fff;
      box-shadow: rgba(0,0,0,0.3)  0px 0px 10px 0px;
      a {
        color: @text-color;
        font-weight: 600;
        text-transform: uppercase;
        &:hover {
          color: @text-color;
        }
      }
      &:hover {
        display: block;
      }
    }
    .acceder-aux-simulateurs {
       display: none;
      @media (min-width:@screen-md-min)  {
          display: block;
          top: 0;
        width: 310px;
        padding: 20px 0;
          font-size: 15px;
        background: @taupe;
        color: #fff;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        &:hover + .panel-separator + .menu-calculettes {
            display: block;
        }
        img {
            margin: 0 10px;
        }
      }
    }
    .view-id-logements_programme_entete_type, .view-id-logements_programme_location_entete_type, .view-id-logements_programme_entete_type_maisons {
      font-size: @font-size-h4;
      background: @gray-light;
      table {
        margin: 0;
        border-top: 0 !important;
        .views-field-field-type-logement {
          padding-left: 3.5%;
          width: 32%;
        }
        .views-field-field-prix {
          text-align: left;
          width: 19%;
        }
        .views-field-nothing {
          padding-right: 4%;
          text-align: right;
          width: 20%;
        }
    		.views-field-field-surface{
    			width: 21%;
    		}
        .views-field-field-mensualite{
            width: 6%;
        }
        .lot-restant{
            width: 22%;
  		p{
  			text-align: center;
  			width: 80%;
  			background: @brand-primary;
  			color: #fff;
  			padding: 1.5px 0 1.5px 0;
  		}
        }
        a {
          display: inline-block;
          height: 28px;
          width: 28px;
          color: @gray-dark;
          border: 1px solid @gray-dark;
          border-radius: 100px;
          text-decoration: none;
          text-align: center;
        }
      }
    }
    .panel-body {
      padding: 0;
      font-size: @font-size-h4;
      .view-id-logements_programme_type, .view-id-logements_programme_location_type, .view-id-logements_programme_type_maisons {
        thead {
          background: @gray-lighter;
          th {
            font-size: @font-size-h4;
            font-weight: 400;
            text-transform: uppercase;
            border-bottom: 0 !important;
            text-align: center;
            &.views-field-field-prix {
              text-align: center;
              padding-left: 32px;

            }
            &.views-field-field-numero-du-lot{
                text-align: left;
            }
          }
        }
        tbody {
          td {
            border-top-style: dashed;
            font-weight: 300;
            &.views-field-field-prix {
              width: 32%;
              padding-left: 1.5%;
              text-align: center;
            }
            &.views-field-field-surface, &.views-field-field-etage, &.views-field-field-plan-du-logement {
              text-align: center;
            }
            &.views-field-field-numero-du-lot{
                padding-left: 3.5%;
            }
          }
          .views-row-first td {
            border-top: 0;
          }
          a {
            color: @text-color;
            text-decoration: underline;
          }
        }
      }
    }
  }
  #programme_section_slider {
    display: none;
    // padding: 70px 0 60px;
    .carousel-control .icon-prev:before, 
    .carousel-control .icon-next:before {
      content: "";
    }
    .carousel-control.left {
      background: rgba(255,255,255,0.7) url(../img/programme-slider-l.png) no-repeat 50% 50%;
      @media screen {
        @media (min-width:1600px) {
          left: -15%;
          background-color: transparent;
        }
      }
    }
    .carousel-control.right {
      background: rgba(255,255,255,0.7) url(../img/programme-slider-r.png) no-repeat 50% 50%;
      @media screen {
        @media (min-width:1600px) {
          right: -15%;
          background-color: transparent;
        }
      }
    }
    .carousel-indicators {
      padding: 5px 0;
      bottom: -10px;
      background: rgba(255, 255, 255, 0.54);
      li {
        background: #fff;
        &.active {
          background: @grisbrun;
        }
      }
    }
  }
  #programme_section_adresse {
    display: none;
    // background: @brand-primary url(../img/bg_adresse.png) repeat-x 50% 0;
    color: #fff;
    .pane-2 {
      font-size: @font-size-h3;
    }
    
    #panel-bootstrap-row-main { padding: 20px 0 15px; }
    .pane-node-field-location {
      margin-top: -10px;
      margin-left: 38px;
      text-transform: uppercase;
      font-size: 35px;
    }
    #panel-bootstrap-column-1 {
      margin-top: -10px;
      @media (min-width:@screen-md-min) {
          border-left: 1px solid #fff;
        }
      padding-top: 10px;
      padding-left: 60px;
      .pane-node-field-location {
        text-transform: uppercase;
        font-size: @font-size-h4;
      }
    }
  }
  #programme_section_actus_contact {
    display: none;
    // padding: 70px 0;
    // #panel-bootstrap-column-main-column {
    //   text-transform: uppercase;
    //   h3 {
    //     font-size: @font-size-h2;
    //     font-weight: 700;
    //   }
    //   p {
    //     font-size: @font-size-h4;
    //     font-weight: 600;
    //   }
    // }
    // Block Appelez-nous page programme
    #block-block-14{
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 1;
      @media screen {
        @media (min-width:@screen-md-min) {
          width: auto;
          top: -15px;
          right: -45px;
        }
      }
    }
  }

  #programme_section_programmes_similaires{
    background-color: @body-bg;
  }
  
}

.node-type-programme-location #panel-bootstrap-region-top_right li:nth-child(2) {
  display: none;
}
