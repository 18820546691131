/**
 * l_grid.less
 */

.col-xs-16.col-sm-16{
	@media (max-width: @screen-md){
		// Bug de Panel, la deuxieme colonne ne contient
		// pas les classe bootstrap pr xs et sm alors 
		// que la première colonne si
		& + .column{
			float: left;
			width: 100%;
		}
	}
}

.panels-bootstrap-1row_2col_1row{
	.tpz_main_container_inside();

	.column-inside-last{
		padding-top: 20px;
	}
}

.pane-sitemap{
	.tpz_main_container_inside();
}

.row--flex{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}