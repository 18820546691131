/**
 * _m-buttons.less
 */

.tpz-reset-btn(){
	border: 0;
	outline: 0;
	padding: 0;
	background-color: transparent;
	cursor: pointer;
	text-decoration: none;
}

.tpz-btn-plus-long(
	@pr: 		25px;
	@pl: 		15px;
	@bgs: 		6px;
	@bgc: 		@c-green;
	@height: 	54px;
	@isbutton:  true;
	){
	.pfe-reset-btn();

	// IF WE PUT THE MIXIN ON A LINK
	& when(@isbutton = false){
		background-color: @bgc;
		background-clip: padding-box;
		display: inline-block;
		border-right: solid @height + 10px transparent;
		text-transform: uppercase;
		height: @height;
		line-height: @height;
		padding-right: @pr;
		padding-left: @pl;
		color: @body-bg;
		position: relative;
		text-decoration: none;
		min-width: 300px;
		text-align: center;

		&:hover,
		&:active,
		&:focus{
			opacity: 0.7;
		}
		
		&:after{
			width: @height;
			line-height: @height - 4px;
			font-size: @height;
			display: block;
			background-color: @bgc;
			background-image: url(../images/background/green-bg.png);
			background-size: @bgs;
			content: "+";
			float: right;
			position: absolute;
			right: 0 - (@height + 10px);
			top: 0; bottom: 0;
			text-align: center;
			font-family: @font-family-serif;
		}
	}
	

	// IF WE PUT THE MIXIN ON A BUTTON
	& when(@isbutton = true){
		position: relative;
		text-transform: uppercase;
		height: @height;
		color: @body-bg;
		font-size: 0;
		& > span{
			height: 100%;
			font-size: @font-size-base;
			background-image: url(../images/background/green-bg.png);
			background-size: @bgs;
			line-height: @height;
			display: inline-block;
			vertical-align: middle;
			font-weight: bold;
			
			&:first-child{
				margin-right: 10px;
				padding-left: @pl;
				padding-right: @pr;
			}
		}

		.icon-plus{
			width: @height;
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			
			svg{
				display: inline-block;
				vertical-align: middle;
			}
			path{
				stroke : currentColor;
			}
		}

		&:hover,
		&:active{
			color: @body-bg;
			span{
				opacity: 0.8;
			}
		}

		// JS parts
		&.js-no-target{
			display: none;
		}
		&.js-reveal,
		&.js-no-reveal{
			path:first-child{
				animation-fill-mode: forwards;
				animation-name: plus_into_minus1;
				animation-duration: 200ms;
				transform-origin: center center 0;
			}
			path:last-child{
				animation-fill-mode: forwards;
				animation-name: plus_into_minus2;
				animation-duration: 200ms;
				transform-origin: center center 0;
			}
		}

		&.js-reveal{
			span{ opacity: 0.8;}
		}
		&.js-no-reveal{
			span{ opacity: 1;}
			path:first-child{
				animation-direction: reverse;
			}
			path:last-child{
				animation-direction: reverse;
			}	
		}

		@media (max-width: @screen-sm){
			height: auto;

			& > span:first-child{
				line-height: 1.3em;
				padding-top: 10px;
				padding-bottom: 10px;
				margin-right: @height + 10px;
			}
			.icon-plus{
				position: absolute;
				top: 0; right: 0; bottom: 0;
				svg{
					position: absolute;
					top: 50%; left: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}
	}
}



.tpz-navbar-toggle(){
	background-color: @brand-primary;
	display: block !important;
	position: absolute;
	right: 0; top: 0;
	height: @navbar-height;
	color: @body-bg;
	font-size: 	1.2em;
	text-transform: uppercase;
	border: 0;
	font-weight: bold;
	border-radius: 0;
	margin: 0;
	padding: 25px 20px;
	text-align: left;
	.pull-left,
	.pull-right {
		float: none !important;
		display: inline-block;
		vertical-align: middle;
	}
	.pull-right{ margin-left: 8px;}
	.icon-bar {
		background-color: @text-color;
		display: block;
		height: 3px;
		width: @grid-gutter-width;
		margin-top: 7px;
		animation-duration: 200ms;
		animation-fill-mode: forwards;
		transition: all 200ms ease-in-out;
		&:first-child,
		&:last-child{
			transform-origin: center center 0;
		}
		&:first-child{ margin-top: 0;}
		&:nth-child(2){ transform-origin: right bottom 0;}
	}
	&[aria-expanded="true"]{
		.icon-bar:first-child{
			transform: translateX(0) translateY(10px) rotateZ(-45deg);
		}
		.icon-bar:nth-child(2){
			opacity: 0;
		}
		.icon-bar:last-child{
			transform: translateX(0) translateY(0 - 10px) rotateZ(45deg);
		}
	}
}



.tpz-btn-plus-right(
	@bottom : 40px;
	@pr 	: 100px;
	@pl 	: 22px;
	@bgs 	: 6px;
	@bgc 	: white;
	){
	height: 69px;
	color: @body-bg;
	line-height: 69px;
	position: relative;
	right: -38px; bottom: @bottom;
	padding-right: @pr;
	display: inline-block;
	text-decoration: none;
	background-color: @c-green;
	padding-left: @pl;
	background-image: url(../images/background/green-bg.png);
	background-size: @bgs;

	&:after{
		content: "";
		width: 77px;
		height: 87px;
		display: block;
		position: absolute;
		top: 0; right: 0;
		box-sizing: content-box;
		border-left: solid 7px @bgc;
		background-image: url(../images/buttons/btn-plus-special.png);
		
	}

	&:hover,
	&:active,
	&:focus{
		color: @body-bg;
		opacity: 0.8;
		text-decoration: none;
	}
}
	