/**
 * c_list-temoignage.less
 */

.list-temoignages {
	.views-row {
		display: block;
		.clearfix();
		clear: both;
		background: #fff;
		margin-top: 30px;
		margin-bottom: 30px;
		padding-bottom: 30px;
	}

	.col:last-child{
		padding-right: 0.5*@grid-gutter-width;
		padding-left: 0.5*@grid-gutter-width;
	}

	.views-field-field-img-header{
		a{
			display: block;
			position: relative;
		}
		img{
			.img-responsive();
			width: 100%;
		}
	}

	.views-field-created {
		text-transform: uppercase;
		font-size: @font-size-h4;
		font-weight: 300;
	}

	.views-field-title,
	.views-field-title-1,
	.views-field-field-programme-ville{
		text-transform: uppercase;
		line-height: 1em;
		font-size: 1.7em;
	}
  
	.views-field-title {
		color: @brand-primary;
		font-weight: 700;
		margin-bottom: 10px;
	}
  
	.views-field-title-1 {
		color: @gray;
		font-weight: 300;
	}
  
	.views-field-field-programme-ville {
		color: @gray-dark;
		font-weight: 600;
	}
  
	.views-field-field-code-postal {
		text-transform: uppercase;
		color: @gray-dark;
		font-size: @font-size-h4;
		line-height: 1;
		margin-bottom: 20px;
	}
	.views-field-body {
		@img_quote_open: '../img/quote_open.png';
		@img_quote_close: '../img/quote_close.png';
		background: url(@img_quote_open) no-repeat 0 0, url(@img_quote_close) no-repeat 100% 100%;
		padding: 25px 0 20px 30px;
		background-size: 30px;

		strong:first-child{
			font-size: 1.3em;
		}
	}
  
	.views-field-view-node {
		text-align: center;
		padding-top: 15px;
		
		.btn-default {
			float: none;
		}
	    
	    a {
			color: #fff;
			text-decoration: none;
	    }
	}

	@media (min-width: @screen-xs){
		.col:last-child{
			padding-right: 36px;
			padding-left: 36px;
		}
		.views-field-title,
		.views-field-title-1,
		.views-field-field-programme-ville{
			font-size: @font-size-h2;
		}

		.views-field-body{
			padding-top: 35px;
			padding-left: 50px;
			background-size: auto;
		}
	}

	@media (min-width: @screen-sm){
	}

	@media (min-width: @screen-md){
		.views-row{
			padding-top: 30px;
		}
		.views-field-field-img-header{
			a:after{
				content: "";
				display: block;
				width: 40px;
				height: 40px;
				position: absolute;
				right: 0;
				top: 50%;
				margin-top: -20px;
				border-style: solid;
				border-width: 20px;
				border-color: transparent;
				border-right-color: white;
			}
		}
		.views-field-body {
			margin-right: 70px;
		}
	}
}