.page-demande-de-rappel {
	.pane-node-body {
		margin-bottom: 80px;
		font-size: 16px;
		font-weight: 300;
	}
	#mini-panel-demande_de_rappel {
		.column-inside-first {
		    @media screen {
		    	@media (min-width:@screen-md-min) {
					padding-right: 70px;
				}
			}
			form {
				.tpz_contact_form;
			}
		}
		.column-inside-last {
			.laissez-nous {
				font-weight: 600;
				.pane-title {
					margin: 0 0 40px;
					font-size: @font-size-h1;
					font-weight: 300;
					color: @gray-dark;
					text-transform: uppercase;
				}
			}
			.numero-telephone {
				margin: 40px 0 0;
				display: inline-block;
				padding: 5px 30px;
				color: #fff;
				background: @brand-primary;
				font-size: 18px;
				strong {
					font-weight: 600;
					font-size: 38px;
				}
			}
		}
	}
}
