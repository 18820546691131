.node-type-page {
  .container {
    margin: 0 auto;
  }

  .pane-title--sidebartoright{
    padding-right: (5*100% / @grid-columns);
  }
  #page_section_contenu {
    #region_node_page_contenu {
      .tpz_main_container;
      .pane-contenu-page-normale {
        .tpz_main_container_inside;
      }
    }
    #anchors_block {
      .tpz_anchors_block;
    }
    .pane-node-title h1 {
      margin: 0;
      text-transform: uppercase;
      color: @brand-primary;
    }
    .pane-node-field-titre-part-1,
    .pane-node-field-titre-part-1 div,
    .pane-node-field-titre-part-2,
    .pane-node-field-titre-part-2 div {
      display: inline;
      line-height: 1;
    }
    #region_node_page_contenu .panel-separator {
      display: none;
    }
    .field-name-field-titre-part-1 {
      font-size: @font-size-h2;
      font-weight: 300;
      color: @brand-primary;
      text-transform: uppercase;
      margin-right: 8px;
      @media screen {
        @media (min-width:@screen-md-min) {
          font-size: @font-size-h1;
        }
      }
    }
    .field-name-field-titre-part-2 {
      font-size: @font-size-h2;
      font-weight: 600;
      color: @gray-dark;
      text-transform: uppercase;
      @media screen {
        @media (min-width:@screen-md-min) {
          font-size: @font-size-h1;
        }
      }
    }
    .pane-node-body {
      font-size: 16px;
      line-height: 1.5;
      padding-top: 1em;
      #anchors_block{
	       margin-top: -17px;
      }
      h2 {
        font-size: @font-size-h3;
        font-weight: 700;
        color: @brand-primary;
        text-transform: uppercase;
        @media screen {
          @media (min-width:@screen-md-min) {
            font-size: @font-size-h2;
          }
        }
      }
      h3 {
        font-size: @font-size-h4;
        font-weight: 700;
        color: @brand-primary;
        text-transform: uppercase;
      }
      h4 {
        font-size: @font-size-h4;
        font-weight: 600;
        color: @gray-dark;
        text-transform: uppercase;
	margin-top: 35px;
      }
      table {
        margin: 30px 0;
        text-transform: uppercase;
        th {
          font-weight: 600;
          padding: 10px 0;
          text-align: center;
        }
        td {
          background: @brand-primary;
          color: #fff;
          font-weight: 700;
          padding: 10px 0;
          text-align: center;
          border: 1px solid #fff;
        }
      }
      ol {
        .tpz_ol;
      }
      img {
        max-width: 100%;
        height: auto !important;
      }
    }
  }
  #page_section_actus_contact {
    .tpz_block_contact;
  }
  #page_section_programmes_similaires {
    .tpz_block_programmes_similaires;
  }
}
