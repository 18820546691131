/**
 * webform.less
 */

.webform-confirmation{
	.alert();
	.alert-success();
	font-size: 1.14em;

	.btn{
		margin-top: 0.5*@grid-gutter-width;
		font-size: 1em;
	}

	& + .links{
		display: none;
	}
}

.webform-submit{
	.btn-s-medium();
	.btn-primary();
	margin-right: 0;
	margin-left: auto;
	display: block;
}

.webform-container-inline{
	label{
		display: none;
		padding-right: @grid-gutter-width;
		margin-right: 0;
	}

	label + .form-control{
		display: inline-block;
		vertical-align: middle;
	}

	@media @tablet{
		label{
			width: 200px;
			display: inline-block;
			vertical-align: middle;
			text-align: right;
		}
		label + .form-control{
			width: ~"calc(100%  - 200px - 2*3px)"
		}
		.form-control{
			.placeholder(transparent);
		}
	}
}

.webform-component{
	label{
		font-size: 1.28rem;
	}
}

.form-type-checkbox{
	display: inline-block;
	margin-right: @grid-gutter-width;

	label{
		font-weight: bold;
	}
}

.webform-component-textarea,
.webform-component-checkboxes{
	& > label{
		margin-bottom: @grid-gutter-width;
		font-weight: normal;
	}
}

.webform-component--hidden{
	margin-bottom: 0;
}

.webform-component--offres-commerciales{
	label{
		font-style: italic;
		font-weight: normal;
		font-size: 1rem;
	}
}

.webform-component--mentions-legales{
	color: @gray;
	font-size: 0.9em;
}

.webform-component-fieldset{
	border: none;
	box-shadow: none;
	background-color: transparent;

	.panel-heading{
		text-transform: uppercase;
		background-color: transparent;
		padding-left: 0;
		color: inherit;
	}
	& > .panel-heading{
		border-color: inherit;
	}
	.panel-title{
		font-size: 1.71rem;
		line-height: 1.2em;
	}
	.panel-body{
		padding-top: 0;
		padding-bottom: 0;
		padding-right: 0;
		padding-left: 0;

		& > .webform-component:first-child{
			padding-top: @grid-gutter-width;
		}
	}
}

.webform-client-form{
	.description{
		font-style: italic;
	}
}

// FORMULAIRE CONTACT GÉNÉRAL
// ----------------------------------------
	.webform-client-form--topaze_contact_mail{
		.webform-component > label {
			display: none;
		}
		.webform-component-fieldset{
			margin-bottom: 0;

			.panel-body > .webform-component:first-child{
				padding-top: 0;
			}
		}
	}


// FORMULAIRE PROPOSER UN TERRAIN
// ----------------------------------------
	.webform-client-form--proposer_un_terrain{
		.webform-component-fieldset{
			margin-bottom: 0;

			.panel-body > .webform-component:first-child{
				padding-top: 0;
			}
		}
	}


// FORMULAIRE PARRAINAGE
// ----------------------------------------
	.webform-client-form--parrainage{
		.webform-component-fieldset{
			margin-bottom: 0;
		}
		.webform-component-checkboxes{
			margin-bottom: 0;
		}
	}


// FORMULAIRE PROJET
// ----------------------------------------
	.webform-client-form--votre_projet{
		
		.webform-component--offres-commerciales{
			label{
				font-weight: normal;
			}
		}
	}



// FORMULAIRE DE CONTACT PROGRAMME
// ----------------------------------------
	.webform-client-form--logement_contact_programme{
		// .tpz_contact_form();
		
		h3{
			text-transform: uppercase;
			font-weight: bold;
		}
		
		label{
			display: none;
		}
		fieldset{
			margin-bottom: 0;
		}
		.webform-component--champs--nom,
		.webform-component--champs--prenom{
			width: 50%;
			float: left;
			padding-right: 0.5*@grid-gutter-width;
			padding-left: 0.5*@grid-gutter-width;
		}
		.webform-component--champs--nom{
			padding-left: 0;
			padding-top: 0;
		}
		.webform-component-fieldset .panel-body > .webform-component:first-child{
			padding-top: 0;
		}
		.webform-component--champs--prenom{
			padding-right: 0;
		}
		.form-control{
			border-color: transparent;
		}
		.webform-component--foot{
			margin-right: auto;
			margin-left: auto;
			text-align: center;
		}
	}