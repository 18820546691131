/**
 * forms.less
 */

input, 
textarea, 
select, 
.uneditable-input {
	max-width: 100%;
	width: auto;
}

input{
	&.error{
		border-color: @state-danger-text;  		
	}
}

fieldset{
	legend.panel-heading {
		float: left;
		line-height: 1em;
		margin: 0;
	}
	.panel-body {
		clear: both;
	}
	.panel-heading a.panel-title {
		color: inherit;
		display: block;
		margin: -10px -15px;
		padding: 10px 15px;
		
		&:hover,
		&:active,
		&:focus {
			text-decoration: none;
		}
	}
}

form{
	.tpz_contact_form();
    .webform-component--champs--votre-projet-concerne {
      margin-bottom: 10px;
    }
}

.form-actions{
	clear: both;
}

.form-item{
	margin-bottom: @grid-gutter-width;
}

.resizable-textarea textarea {
	border-radius: @border-radius-base @border-radius-base 0 0;
}

.radio, 
.checkbox {
	&:first-child { margin-top: 0;}
	&:last-child { margin-bottom: 0;}
}

.form-control{
	box-shadow: none;
	&:-moz-placeholder { font-style: italic; }
	&::-moz-placeholder { font-style: italic; }
	&:-ms-input-placeholder { font-style: italic; } // Internet Explorer 10+
	&::-webkit-input-placeholder { font-style: italic; } // Safari and Chrome
}



// FORMULAIRE DE CONTACT PROGRAMME
// ----------------------------------------
	.pane-webform--contact-programme{
		// .tpz_contact_form();
		
		.bg--topaze();
		background-color: @brand-primary;
		padding: @grid-gutter-width;
		color: white;

		.webform-submit{
			.btn-secondary();
			margin-right: auto;
			margin-left: auto;
			// margin-bottom: @grid-gutter-width;
		}
	}