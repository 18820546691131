.node-type-calculette {
  .container {
    margin: 0 auto;
  }

  #page_section_contenu {
    #region_node_page_contenu {
      .tpz_main_container;
      .pane-contenu-calculette {
        .tpz_main_container_inside;
      }
    }
    .pane-node-title h1 {
      margin: 0;
      text-transform: uppercase;
      color: @brand-primary;
    }
  }
  #panel-bootstrap-region-droite {
    margin-top: 60px;
    .pane-1 {
      h2 {
        color: @brand-primary;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }
}
