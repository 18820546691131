/**
 * item-programme.less
 */

// .tb-megamenu-nav{
//     li:first-child{
//         .tb-megamenu-submenu{
//             display: block;
//             opacity: 1 !important;
//             margin: 0 !important;
//         }
//         .mega-dropdown-inner{
//             margin: 0 !important;
//         }
//     }
// }

.item-program--megamenu{
    position: relative;
    
    .views-row {
        overflow: hidden;
        display: flex;
        align-items: stretch;

        & > div{
            position: relative;
        }
        & > div:first-child .field-content{
            position: absolute;
            top: 0;
            bottom: 0;
            background-color: @brand-info;
        }

        & > div:last-child{
            flex:1;
        }
    }

    .views-field-field-img-header{
        width: 84px;
        flex-basis: 84px;
        overflow: hidden;
        a {
            padding: 0;
            height: 100%;
            display: inline-block;
        }
        img{
            height: 100%;
            width: auto;
            display: inline-block;
        }
    }


    .descriptif {
        background: rgba(255, 255, 255, 0.85);
        text-align: right;
    }

    .views-field-title,
    .views-field-field-nom-programme {
        margin: 20px 15px 0;
        color: @brand-primary;
        font-weight: 600;
        font-size: @font-size-h4;
        a {
            padding: 0;
            color: @brand-primary;
            text-decoration: none;
        }
    }
    .views-field-field-programme-ville,
    .views-field-field-code-postal {
        margin: 0 15px;
        font-weight: 600;
    }
    .views-field-field-accroche {
        margin: 20px 15px;
        text-transform: none;
    }

    .views-field-field-programme-evt-offre {
        padding: 10px 10px 15px;
        background: @brand-primary;
        color: #fff;
        font-weight: 600;
    }
}