/**
 * breadcrumb.less
 */

.breadcrumb{
	background-color: transparent;
	color: @brand-primary;
	font-weight: bold;
	display: table;
	padding-right: 30px;
	border-bottom: solid 1px;
	margin-top: 5px;

	& > .active,
	& > .last,
	& > .inline:last-child{
		color: lighten(@brand-primary, 10%);
		font-weight: normal;
	}
	
	.delimiter,
	& > li + li:before{
		content: "//";
		color: inherit;
		color: @brand-primary;
		font-weight: normal;
		display: inline;
	}

	& > li + li:before{
		padding-right: 11px;
		padding-left: 7px;
	}

	.delimiter{
		padding-right: 9px;
		padding-left: 4px;
	}
}

.breadcrumb--topzen{
	padding: 0;
	border-bottom-width: 0;
	display: none;
	margin: -30px -45px 0;

	.nav{
		font-size: 0.8em;
		display: flex;

		a{
			display: inline-block;
			vertical-align: middle;
			color: @text-color;
			line-height: 1.1em;
			padding: 0 0 0 5px;
			width: 100%;
			background-color: transparent;
			span {
			  	color: @brand-primary;
			}
			&:hover, 
			&:focus {
				background-color: inherit;
			}
		}
		& > li{
			font-weight: 700;
			text-transform: uppercase;
			text-align: center;
			background-position: left center;
			background-repeat: no-repeat;
			padding-left: 40px;
			height: 79px;
			line-height: 79px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex: 1;
			&.odd {
				background-color: @gray-light;
				background-image: url(../img/etapes_top_zen/even_to_odd.jpg);
			}
			&.even {
				background-color: #D9DADE;
				background-image: url(../img/etapes_top_zen/odd_to_even.jpg);
			}
			&.first {
				background-image: none;
				padding-left: 0;
			}
		}

		li.active{
			background-color: @brand-primary;
			&.odd {
				&:not(.first){
			  		background-image: url(../img/etapes_top_zen/even_to_active.jpg);
				}
			  	& + li {
			    	background-image: url(../img/etapes_top_zen/active_to_even.jpg);
			  	}
			}
			&.even {
			  	background-image: url(../img/etapes_top_zen/odd_to_active.jpg);
			  	& + li {
			    	background-image: url(../img/etapes_top_zen/active_to_odd.jpg);
			  	}
			}
			a {
			  	color: #fff;
			  	span {
			    	color: @text-color;
			  	}
			}
		}
	}

	@media (min-width: @screen-md-min) {
		display: block;
	}
	@media (min-width: @screen-lg-min) {
		.nav{
			font-size: 1em;
		}
	}
}