.page-trouver-votre-bien {
  .container {
    margin: 0 auto;
  }
  #page_section_contenu {
    #region_node_page_contenu {
      // .tpz_main_container;
      padding: 0px 15px;
    }
  }
}
