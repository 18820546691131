/**
 * temoignage.less
 */

.panels-bootstrap-temoignages {
	.pane-1 {
		.pane-title {
		  color: #d2d2d2;
		  font-size: @font-size-h3;
		  font-weight: 600;
		  line-height: 1;
		}
	}
	.pane-2 {
		font-size: @font-size-h4;
		margin: 55px 0 20px;
	}

	#page_section_contact {
		.tpz_block_contact;
	}
}
