/* Styles réutilisables */

/* Image header */
.tpz_header_img {
  width: 100%; overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  img {
    display: block;
    max-width: 100%;
    height: auto !important;
  }
}

/* Filtres de recherche */
.tpz_search_filters {
    background-color: @brand-primary;

    .pane-facetapi {
        margin: 0 0 15px 0;
        .pane-content {
            // background: #fff url(../img/select-arrow.png) no-repeat 95% 50%;
            border-style: solid;
            border-width: 1px;
            position: relative;
            width: 100%;
            display: block;
            

            &:after{
                content: '';
                display: block;
                position: absolute;
                top: -1px; right: -1px;
                bottom: 50%; left: -1px;
                background-color: @brand-primary;
            }

            select {
                padding: 10px 20px;
                width: 100%;
                border: 0;
                background-image: none;
                border-radius: 0;
                // background: rgba(0, 0, 0, 0.005);
                background-color: transparent;
                position: relative;
                z-index: 1;
            }
            option{
                color: black;
            }
        }
        .form-type-checkbox {
            input {
                display: none;
            }
        }
    }
    
    .btn-default {
        display: block;
        float: none;
        padding-top: 13px;
        padding-bottom: 13px;
        background-color: @c-blue_dark;
        color: white;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > span{
            display: inline-block;
        }
        & > span:before{
            content: '';
            display: block;
            margin-right: auto;
            margin-left: auto;
            width: 52px;
            height: 52px;
            background-image: url(../img/zoom.svg);
            background-size: contain;
            background-repeat: no-repeat;
            margin-bottom: 12px;
        }

        &:hover,
        &:active,
        &:focus{
            background-color: @c-blue_darker;
        }

        @media (max-width: @screen-md){
            & > span:before{
                display: inline-block;
                margin-bottom: 0;
                vertical-align: middle;
                margin-right: 12px;
                width: 30px;
                height: 30px;
            }
        }
    }
    .row-inside-last {
        padding: 13px 0;
        font-size: 0.8em;
    }
}


/* Onglets recherche */
.tpz_search_tabs {
    margin: 0 auto;
    ul {
        padding-left: 0;
        height: 100%;
        list-style-type: none;
        margin-bottom: 0;
    }
    li {
        vertical-align: top;
        width: 100%;
        height: 50%;
    }    
    a {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        line-height: 45px;
        padding-left: 60px;
        color: #fff;
        text-transform: uppercase;
        font-size: @font-size-h3;
        background-color: @c-blue_dark;
        background-position: 30px 50%;
        background-repeat: no-repeat;
        &:hover,
        &:focus,
        &:active,
        &.active {
            text-decoration: none;
            background-color: @c-blue_darker;
        }
        &.active{
            font-weight: bold;
        }
        &.habiter {
            background-image: url(../img/onglets_recherche/habiter.png);
            &.active {
              // background-image: url(../img/onglets_recherche/habiter_active.png);
            }
        }
        &.investir {
            background-image: url(../img/onglets_recherche/investir.png);
            &.active {
              // background-image: url(../img/onglets_recherche/investir_active.png);
            }
        }
    }

    @media (max-width: @screen-md){
        ul{
            .clearfix();
        }
        li{
            width: 50%;
            float: left;
        }
    }

    @media (max-width: @screen-xs){
        li{
            width: 100%;
            float: none;
        }
    }
}

/* Décalage vers le haut du conteneur principal */
.tpz_main_container {
  padding: 10px 15px;
  // outline: 10px solid rgba(245, 245, 245, 0.5);
  background: @body-bg;

  & > .pane-logement-filtres-habitation,
  & > .pane-logement-filtres-investir{
    box-shadow : 0px 7px 17px 2px lighten(black, 60%);
    background-color: @gray-lighter;
    margin-top: -30px;
    margin-right: -45px;
    margin-left: -45px;
    padding: 30px;
  }

  @media screen {
    @media (min-width:@screen-md-min) {
      // margin-top: -40px;
      margin-bottom: 40px;
      padding: 30px 45px;
    }
    @media (min-width:@screen-lg-min) {
      margin-top: -80px;
      margin-bottom: 30px;
    }
    @media (min-width:1600px) {
      margin-top: -90px;
    }
  }
}
.tpz_main_container_inside {
  background: #fff;
  padding: 10px 15px;
  @media screen {
    @media (min-width: @screen-md-min) {
      padding: 30px 45px;
    }
  }
}

/* Titre rubrique */
.tpz_titre_rubrique {
  margin: 0;
  color: @gray;
  font-weight: 600;
  font-size: @font-size-h3;
}

/* Onglets ancres */
.tpz_anchors_block {
  ul {
    float: right;
    margin: -60px 0 20px 100%;
    li {
      margin: 0 0 10px;
      list-style-type: none;
      list-style-image: none;
      text-align: center;
      text-transform: uppercase;
      a {
        display: block;
        padding: 8px 30px;
        background: @taupe;
        color: #fff;
        font-weight: 700;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

/* Formulaire de contact embarqué */
.tpz_block_contact {
  position: relative;
  background-color: @brand-primary;
  color: #fff;
  .pane-2 {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 25px 35px;
    background: @brand-primary;
    font-size: @font-size-h4;
    line-height: 1;
      width:100%;
    @media screen {
      @media (min-width:@screen-md-min) {
          width: auto;
        top: -15px;
        right: -45px;
      }
    }
    strong {
      font-size: 38px;
      font-weight: 600;
    }
  }
  .webform-client-form {
    .tpz_contact_form;
    padding: 45px 25px 15px;
    
    .panel.webform-component-fieldset {
      // display: inline-block;
      // vertical-align: top;
      // @media screen {
      //   @media (min-width:@screen-md-min) {
      //     width: 45%;
      //   }
      // }
      // &.webform-component--message {
      //   @media screen {
      //     @media (min-width:@screen-md-min) {
      //       width: 51.5%;
      //       margin-left: 3%;
      //     }
      //   }
      // }
    }
  }

  .webform-submit{
    .btn-secondary();
    margin-right: auto;
    margin-left: auto;
  }
}

/* Formulaires de contact */
.tpz_contact_form {
  .tpz_form;
  .webform-component--contactez-nous {
    label {
      text-transform: uppercase;
      font-size: @font-size-h2;
      font-weight: 300;
      .form-required {
        // display: none;
      }
    }
    .form-type-radio {
      display: inline-block;
      vertical-align: top;
      margin: 0 10px 10px 0 !important;
      label {
        text-transform: none;
        font-size: @font-size-h4;
        font-weight: 300;
      }
    }
  }
  .panel.webform-component-fieldset {
    // border: 0;
    // background: transparent;
    // .panel-body {
    //   padding: 0;
    // }
  }
  // .webform-component--champs--nom, 
  // .webform-component--champs--prenom {
  //   display: inline-block;
  //   width: 49%;
  // }
  // .webform-component--champs--heure-de-rappel {
  //   margin-top: 5px;
  //   .checkbox {
  //     display: inline-block;
  //     width: 49%;
  //   }
  // }
  // .webform-component--champs--email {
  //   width: 99%;
  //   margin-top: 3px;
  // }
  // .webform-component--champs--telephone {
  //   width: 99%;
  // }
  // .webform-component--message--commentaires {
  //   float: right;
  // }
  // .webform-component--message--votre-projet-concerne {
  //   margin-bottom: 10px;
  // }
}

/* Formulaires */
.tpz_form {
  .tpz_checkbox;
  ::-webkit-input-placeholder { color: @gray; font-style: italic; }
  ::-moz-placeholder { color: @gray; font-style: italic; } /* firefox 19+ */
  :-ms-input-placeholder { color: @gray; font-style: italic; } /* ie */
  input:-moz-placeholder { color: @gray; font-style: italic; }
  
  .radio input[type="radio"], 
  .radio-inline input[type="radio"], 
  .checkbox input[type="checkbox"], 
  .checkbox-inline input[type="checkbox"] {
    margin-left: 0;
  }
}

/* Boutons */
.btn-default {
  background: @brand-primary;
  border: 0;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  float: right;
  margin-bottom: 20px;
  &:hover {
    background-color: @gray;
    color: @text-color;
    text-decoration: none;
  }
}

/* Listes à puces */
.tpz_ul {
  li {
    list-style-image: url(../img/bullet.png);
    margin: 4px 0;
    display: list-item;
  }
}

/* Listes à puces numérotées */
.tpz_ol {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  counter-reset: tpzol;
  padding-left: 0;
  li {
    list-style-type: none;
    list-style-position: outside;
    counter-increment: tpzol;
    margin-bottom: 5px;
    &:before {
      content: counter(tpzol);
      display: inline-block;
      vertical-align: middle;
      font-size: @font-size-h4;
      font-weight: 600;
      width: 28px;
      height: 28px;
      margin-right: 8px;
      color: @gray;
      text-align: center;
      border: 2px solid @gray;
      border-radius: 99px;
    }
  }
}

/* Bloc "vous aimerez aussi" */
.tpz_block_programmes_similaires {
  // background-color: red;
  text-align: center;
  text-transform: uppercase;
  h2 {
    margin: 70px 0;
    font-weight: 600;
  }
  .views-row {
    display: inline-block;
    vertical-align: top;
    // background: #fff;
    background-color: white;
    margin: 0 1em 3em;
    padding: 1.5em;
    width: 90%;
    box-shadow: 0px 3px 15px -3px fadeout(black, 50%);

    @media screen {
      @media (min-width:@screen-md-min) {
        width: 45%;
      }
      @media (min-width:@screen-lg-min) {
        width: 30%;
      }
    }
    .views-field-title, 
    .views-field-name, 
    .views-field-field-programme-cat-principale {
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 0;
      a {
        color: @text-color;
      }
      &:after {
        content: "_";
        display: block;
        margin: -10px 0 10px;
      }
    }
    .views-field-title,
    .views-field-field-programme-cat-principale{
      font-size: 1.57142857143em;
    }
    img {
      &:extend(.img-responsive);
    }
    .views-field-view-node,
    .views-field-nothing {
      margin: 35px 0 25px;
      a {
        display: block;
        width: 100%;
        background: @brand-primary;
        color: #fff;
        padding: 6px;
        font-weight: 700;
      }
    }
  }
}

/* http://www.creativejuiz.fr/blog/tutoriels/personnaliser-aspect-boutons-radio-checkbox-css */
.tpz_checkbox {
	/* Cachons la case à cocher */
	[type="checkbox"]:not(:checked),
	[type="checkbox"]:checked {
	  position: absolute;
	  left: -9999px;
	}

	/* on prépare le label */
	[type="checkbox"]:not(:checked) + label,
	[type="checkbox"]:checked + label {
	  position: relative; /* permet de positionner les pseudo-éléments */
	  padding-left: 25px + 0.5*@grid-gutter-width; /* fait un peu d'espace pour notre case à venir */
	  cursor: pointer;    /* affiche un curseur adapté */
	}
	/* Aspect des checkboxes */
	/* :before sert à créer la case à cocher */
	[type="checkbox"]:not(:checked) + label:before,
	[type="checkbox"]:checked + label:before {
	  content: '';
	  position: absolute;
	  left:0; top: 0px;
	  width: 23px; height: 23px; /* dim. de la case */
	  background: #fff;
    border: 1px solid #ccc;
	}

	/* Aspect général de la coche */
	[type="checkbox"]:not(:checked) + label:after,
	[type="checkbox"]:checked + label:after {
	  content: '✔';
	  position: absolute;
	  top: 0; left: 4px;
	  font-size: 18px;
	  color: @brand-primary;
	  transition: all .2s; /* on prévoit une animation */
	}
	/* Aspect si "pas cochée" */
	[type="checkbox"]:not(:checked) + label:after {
	  opacity: 0; /* coche invisible */
	  transform: scale(0); /* mise à l'échelle à 0 */
	}
	/* Aspect si "cochée" */
	[type="checkbox"]:checked + label:after {
	  opacity: 1; /* coche opaque */
	  transform: scale(1); /* mise à l'échelle 1:1 */
	}
}

.tpz_flag_bookmark {
  display: inline-block;
  vertical-align: top;
  margin: 16px;
  a.flag {
    outline: 0;
    display: block;
    text-indent: -5000px;
    width: 44px; height: 38px;
    &.flag-action {
      background: url(../img/bookmark.png) no-repeat 0 0;
    }
    &.unflag-action {
      background: url(../img/unbookmark.png) no-repeat 0 0;
    }
  }
}