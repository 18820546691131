/**
 * colorbox.less
 */

#colorbox{
}

#cboxWrapper{
	border-radius: 0;
	background-color: @brand-primary;
	.bg--topaze();
}

#cboxContent{
	background-color: transparent;

	// Glyphicon d'attente
	.glyphicon();
	.glyphicon-refresh();
	&:before{
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -36px;
		margin-top: -36px;
		font-size: 72px;
		color: white;
		animation: spin 1200ms linear infinite;
	}
	
	html{
		background-color: transparent;
	}
	body{
		background-color: transparent;
	}
}
.colorbox--is-loaded{
	#cboxContent{
		&:before{
			display: none;
		}
	}
}

#cboxLoadedContent{
	position: relative;
	// background-color: @brand-primary;
}

#cboxClose{
	top: 0;
	background-image: none;
	background-color: lighten(@brand-primary, 10%);
	width: 40px;
	height: 40px;
	text-transform: uppercase;
	// position: relative;
	

	// Cross
	&:before,
	&:after{
		content: '';
		background-color: white;
		display: block;
		height: 2px;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 27px;
	}
	&:before{
		transform: translateX(-50%) translateY(-50%) rotateZ(45deg);
	}
	&:after{
		transform: translateX(-50%) translateY(-50%) rotateZ(-45deg);
	}
}

