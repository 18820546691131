// VENDORS
// ----------------------------------
@import "variables_import.less";
@import "bootstrap.less";
@import "slick.less";
@import "select2.less";

// Base-theme overrides.

// Mixins
@import 'utils/animations.less';
@import 'utils/mixins/m-loader.less';
@import 'utils/mixins/m-buttons.less';
@import 'utils/mixins.less';

@import 'base/base.less';
@import 'base/overrides.less';

// Theme specific
@import 'layouts/header.less';
@import 'layouts/grid.less';
@import 'layouts/section-image-header.less';
@import 'content.less';
@import 'layouts/footer.less';


// Components
@import 'components/glyphicons.less';
@import 'components/slick.less';
@import 'components/icon-svg.less';
@import 'components/accordion.less';
@import 'components/alerts.less';
@import 'components/toolbar.less';
@import 'components/img-card.less';
@import 'components/lists.less';
@import 'components/c_breadcrumb.less';
@import 'components/c_btn.less';
@import 'components/modal.less';
@import 'components/c_blocks.less';
@import 'components/carousel.less';
@import 'components/menu-transversal.less';
@import 'components/c_section-teaser.less';
@import 'components/c_list-prog-macarons.less';
@import 'components/c_list-temoignages.less';
@import 'components/item-program.less';
@import 'components/c_list-programmes.less';
@import 'components/c_recherche-programmes.less';
@import 'components/forms.less';
@import 'components/webform-demande-brochure.less';

@import 'programme-search-results.less';


// Modules
@import 'modules/panel.less';
@import 'modules/colorbox.less';
@import 'modules/webform.less';
@import 'modules/views.less';


// Nodes
@import 'nodes/node-type-page.less';
@import 'nodes/node-type-programme.less';
@import 'nodes/node-type-calculette.less';
@import 'nodes/node-type-webform.less';
@import 'nodes/node-type-actualites.less';
@import 'nodes/node-type-page-simple.less';

// Page specific
@import 'pages/page-contact.less';
@import 'pages/demande-plan.less';
@import 'pages/demande-rappel.less';
@import 'pages/frontpage.less';
@import 'pages/investir-avec-topaze.less';
@import 'pages/mes-programmes-favoris.less';
@import 'pages/nos-realisations.less';
@import 'pages/parrainage.less';
@import 'pages/proposer-un-terrain.less';
@import 'pages/temoignages.less';
@import 'pages/top-zen.less';
@import 'pages/trouver-votre-bien.less';
@import 'pages/user.less';
@import 'pages/body-in-iframe-in-modal.less';
@import 'pages/page-node-done.less';
@import 'pages/inscription-soiree.less';
@import 'pages/page-actualites.less';
@import 'pages/page-taxonomy-villes-programmes.less';
@import 'pages/page-programmes.less';

.container-fluid {padding-left:0;padding-right:0}
.container-fluid [class*="col-"] {padding-left:0;padding-right:0}
.container-fluid .row {margin-left:0;margin-right:0}

// Admin menu
@media screen {
  @media (max-width:@screen-md-min) {
    html body.admin-menu {
      margin-top: 0 !important;
    }
    body.admin-menu .navbar-fixed-top {
      top: 0;
    }
    #admin-menu {
      display: none;
    }
  }
}

// Colorbox
#cboxLoadedContent {
  .page-header {
    font-size: @font-size-h2;
    font-weight: 700;
    color: @brand-primary;
    padding-bottom: 0;
    text-transform: uppercase;
  }
  #navbar, .tabs--primary, .footer_top, #footer_wrapper, #toolbar_region {
    display: none;
  }
}

// Print
@import 'print.less';
