@media print {

	#simplemeta-meta-form-ajax-wrapper,
	#programme_section_descriptif .pane-node-field-programme-cat-principale::after,
	#mini-panel-logement_evenement_programme,
	#programme_section_lots,
	#programme_section_slider,
	#programme_section_adresse,
	#programme_section_carte,
	#programme_section_actus_contact,
	#programme_section_brochure,
	#programme_section_programmes_similaires,
	.tabs--primary.nav.nav-tabs,
	#footer_top,
	#block-menu-menu-menu-mentions-legales,
	#footer_wrapper,
	#anchors_block,
	.node-type-page #page_section_actus_contact{
		display: none;
	}

	.wow {
		display: block;
	}

}
